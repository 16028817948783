import React from "react";

function Icon() {
  return (
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40.625 11.375L11.375 40.625" stroke="#0092dd" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M40.625 40.625L11.375 11.375" stroke="#0092dd" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default Icon;
