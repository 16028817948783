import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="62"
      height="62"
      fill="none"
      viewBox="0 0 62 62"
    >
      <path
        fill="#0092DD"
        d="M30.895 0C13.832 0 0 13.832 0 30.895c0 17.062 13.832 30.894 30.895 30.894 17.062 0 30.894-13.832 30.894-30.894C61.789 13.832 47.956 0 30.895 0zm20.954 31.515h-7.727a1.353 1.353 0 00-1.354 1.355v13.342a1.354 1.354 0 01-1.354 1.354H19.748a1.355 1.355 0 01-1.353-1.354V32.87a1.352 1.352 0 00-1.353-1.354H9.937c-.748 0-.936-.44-.421-.982L29.961 9.019a1.272 1.272 0 011.866 0l20.447 21.514c.515.543.327.982-.421.982h-.004z"
      ></path>
    </svg>
  );
}

export default Icon;
