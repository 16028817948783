import React from "react";

function Icon() {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 3.2069V1" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.3449 1L18.6553 1" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 30.7931L17 33" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.6551 33L15.3447 33" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M30.7931 17L33 17" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M33 15.3448L33 18.6552" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.2069 17L1 17" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1 18.6552L1 15.3448" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.1039 5.05457L9.00049 3.14334" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.56675 3.97091L10.4336 2.31573" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M23.8961 28.9454L24.9995 30.8567" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M26.4333 30.0291L23.5664 31.6843" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M28.9452 10.1037L30.8564 9.00021" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M30.0289 7.56681L31.6841 10.4337" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.05527 23.8963L3.14404 24.9998" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.97158 26.4332L2.31641 23.5663" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.05527 10.1032L3.14404 8.99979" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2.31651 10.4332L3.97168 7.56635" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M28.9447 23.8968L30.856 25.0002" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M31.6835 23.5668L30.0283 26.4337" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M23.8966 5.05509L25 3.14386" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M23.5663 2.31629L26.4331 3.97147" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.1034 28.9449L9 30.8561" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.4337 31.6837L7.56689 30.0285" stroke="#EA6D4F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M30.1432 17C30.1432 24.2587 24.2589 30.1431 17.0001 30.1431C9.7414 30.1431 3.85703 24.2587 3.85703 17C3.85703 9.74128 9.7414 3.85691 17.0001 3.85691C24.2589 3.85691 30.1432 9.74128 30.1432 17Z" stroke="#EA6D4F" strokeWidth="1.3"/>
        <path d="M18.005 16.4483C18.005 17.3081 17.308 18.0052 16.4481 18.0052C15.5883 18.0052 14.8912 17.3081 14.8912 16.4483C14.8912 15.5884 15.5883 14.8914 16.4481 14.8914C17.308 14.8914 18.005 15.5884 18.005 16.4483Z" stroke="#EA6D4F" strokeWidth="1.3"/>
        <path d="M18.005 8.72414C18.005 9.58399 17.308 10.281 16.4481 10.281C15.5883 10.281 14.8912 9.58399 14.8912 8.72414C14.8912 7.86429 15.5883 7.16724 16.4481 7.16724C17.308 7.16724 18.005 7.86429 18.005 8.72414Z" stroke="#EA6D4F" strokeWidth="1.3"/>
        <circle cx="17.5516" cy="24.1724" r="2.66034" stroke="#EA6D4F" strokeWidth="1.3"/>
        <path d="M10.8944 12.1266C11.4527 12.449 11.644 13.1629 11.3217 13.7213C10.9993 14.2796 10.2854 14.4709 9.72702 14.1486C9.16867 13.8262 8.97737 13.1122 9.29973 12.5539C9.62209 11.9956 10.336 11.8042 10.8944 12.1266Z" stroke="#EA6D4F" strokeWidth="1.3"/>
        <circle cx="24.522" cy="20.0454" r="1.33526" transform="rotate(-60 24.522 20.0454)" stroke="#EA6D4F" strokeWidth="1.3"/>
        <path d="M9.88967 20.1321C10.2927 19.8994 10.8081 20.0375 11.0407 20.4406C11.2734 20.8436 11.1353 21.359 10.7323 21.5916C10.3293 21.8243 9.81393 21.6862 9.58124 21.2832C9.34855 20.8802 9.48664 20.3648 9.88967 20.1321Z" stroke="#EA6D4F" strokeWidth="1.3"/>
        <path d="M22.6346 11.3118C23.6432 10.7295 24.9328 11.0751 25.515 12.0836C26.0973 13.0921 25.7518 14.3817 24.7432 14.964C23.7347 15.5463 22.4451 15.2008 21.8628 14.1922C21.2805 13.1837 21.6261 11.8941 22.6346 11.3118Z" stroke="#EA6D4F" strokeWidth="1.3"/>
    </svg>
  );
}

export default Icon;
