import React from 'react'
import styled from 'styled-components'

import TechoBig from '../Icons/TechoBig'

const TechoHeader = () => {

  return (
    <Wrapper>
      <TechoBig />
      <Desc>
        Relevamiento de <br/>
        Asentamientos <br/>
        Populares
      </Desc>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 120px;
  padding: 2rem 2rem 0.5rem 2rem;

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    padding: 2.2rem 0.5rem;
  }
`

const Desc = styled.div`
  color: white;
  text-align: center;
  font-size: 12px;

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`

export default TechoHeader