import React from 'react'
import styled from 'styled-components'
import TechoHeader from '../TechoHeader'

const SplashScreen = () => {
  return (
    <Wrapper>
      <TechoHeader />
      <p>
        Cargando...
      </p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #01021e;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > p {
    font-size: 12px;
    color: white;
    text-align: center;
  }
`

export default SplashScreen