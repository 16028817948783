import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M26 18C26 9 16 2 16 2S6 9 6 18a10 10 0 0020 0zM17.015 23.902a5.99 5.99 0 004.9-4.895"
      ></path>
    </svg>
  );
}

export default Icon;
