import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Context } from '../../utils/context'
import { fetchCovidSettlement } from '../../utils/fetch'

import DetailsWrapper from '../../components/DetailsWrapper'
import Covid from './assets/Covid'

import { covidKeys } from './labels'

const dimensions = [
  {
    name: 'Covid-19',
    keys: covidKeys,
    icon: (<Covid />)
  }
]

const CovidDetails = ({history}) => {

  const context = useContext(Context)
  const { settlementId } = useParams()

  const [covidData, handleCovidData] = useState([]);

  useEffect(() => {
    if (context.selected !== 'covid') {
      context.updateSelected('covid')
    }

    fetchCovidSettlement(settlementId, handleCovidData, context.updateCovidSettlement)
  }, [])

  return (
    <DetailsWrapper
      onClose={() => {
        context.updateCovidSettlement(null)
        context.updateSettlement(null)
        context.handleShowMarkerSelected(false)
        context.handleShowPopup(false)
        history.push('/')
      }}
      dimension={'Covid-19'}      
      dimensions={dimensions}
      keys={covidKeys}
      covidData={covidData && covidData.data}
      settlementData={null}
    >                  
    </DetailsWrapper>
  )
}

export default CovidDetails;