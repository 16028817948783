import React, { useContext } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom'
import { Context } from '../../../utils/context'

const Result = ({ settlement, history, type }) => {
  const { id, name, country, province } = settlement;
  const context = useContext(Context)

  return (
    <Wrapper>
      <Results key={id}>
        <ResultsWrapper onClick={() => {
          if (type === 'general') {
            history.push(`/asentamientos/${settlement.id}`)
          } else {
            context.updateCovidSettlement(settlement)
            history.push(`/covid/${settlement.settlement_id}`)
          }
        }}> 
          <Settlement>
            {name}
          </Settlement>
          <Country>
            {country}, {province}
          </Country>
        </ResultsWrapper>
      </Results>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Results = styled.div`
  border-bottom: 1px solid #33A9E7;

  margin: 0 1.25rem;
  padding: 1rem 0;

  :hover {
    background: ${props => props.theme.colors.secondaryBlue}
  }
`

const ResultsWrapper = styled.div`
  margin: 0 1rem;
  cursor: pointer;
`

const Settlement = styled.div`
  color: white;
  font-weight: 600;
  font-size: ${props => props.theme.fontSizes.sm};

  margin: 5px 0;
`

const Country = styled.div`
  color: white;
  font-weight: 400;
  font-size: ${props => props.theme.fontSizes.xs};
`

export default withRouter(Result);