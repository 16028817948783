import React from 'react'
import styled from 'styled-components'
import { clone } from 'lodash'

import Check from '../../../Icons/Check'

const CategoryItem = ({label, dbKey, values, filter, filters, handleFilters}) => {
  return (
    <Wrapper>
      <Title>
        { label } 
      </Title>
      { values.map((v, i) => (
          <CheckboxWrapper key={i} onClick={() => {
              if (filter && filter.includes(v)) {
                let newFilters = clone(filter).filter(f => f !== v)
                handleFilters({
                  ...filters,
                  [dbKey]: newFilters
                })
              } else {
                let newFilters = clone(filter) || []
                newFilters.push(v)
                handleFilters({
                  ...filters,
                  [dbKey]: newFilters
                })
              }
            }}
          >
            <Checkbox 
              selected={filter && filter.includes(v)}              
            />

            { filter && filter.includes(v) && (
              <CheckWrapper>
                <Check />
              </CheckWrapper>
            )}

            <CheckboxLabel>
              { v }
            </CheckboxLabel>
          </CheckboxWrapper>          
      ))
      }
    </Wrapper>
  )  
}

const CheckWrapper = styled.div`
  position: absolute;
  top: 9px;
  left: 0;
  background: #0785C6;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 12px;
    height: 12px;
  }
`
const CheckboxWrapper = styled.div`
  position: relative;
  display: flex;
  padding-top: 0.5rem;
  align-items: center;
`

const Checkbox = styled.div`
  border-radius: 50%;
  min-width: 16px;
  min-height: 16px;
  border: ${props => props.selected ? 'none' : '1px solid #fff'};
  cursor: pointer;
  margin-right: 1rem;
  transition: all 0.2s;  
`

const CheckboxLabel = styled.div`
  color: white;
`

const Wrapper = styled.div`
  padding-top: 1rem;
`

const Title = styled.div`
  font-size: 14px;
  color: white;
`

export default CategoryItem