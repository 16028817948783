import React from 'react';
import styled from 'styled-components';

import { search } from '../SearchBar/api'

import SearchIcon from '../../components/Icons/Search'

const Input = ({ handleSettlements, type }) => {
  const runSearch = async (inputVal) => {
    // Capitalize each word of the string received as param
    const capitalize = (inputVal)  => {
      return inputVal.split(" ").map(word => word.substring(0, 1).toUpperCase() + word.substring(1)).join(" ")
    }

    let capitalizedVal = capitalize(inputVal);

    const settls = await search(capitalizedVal, type);

    handleSettlements(settls);
  }

  return (
    <Wrapper>
      {/* Run search only if value entered has more or equal characters than 4 */}
      <StyledInput onChange={(e) => e.target.value.length >= 4 ? runSearch(e.target.value) : false} />
      <IconWrapper>
        <SearchIcon />
      </IconWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  border: none;
  border-radius: 25px;

  padding: 1rem 1.25rem;
  margin: 0.5rem 1rem;

  color: #FFFFFF;
  font-size: ${props => props.theme.fontSizes.base};
  background: ${props => props.theme.colors.secondaryBlue};

  :focus {
    box-shadow: 0 0 0 0.5px #fff;
    outline-width: 1px;
    /* outline-color: #fff; */
  }

  :focus-visible {
    outline: none;
  }

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
  }
`


const IconWrapper = styled.div`
  position: absolute;
  right: 0;

  margin: 20px;
  padding: 0 10px;

  width: 20px;
`

export default Input;