import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Context } from '../../utils/context'
import { fetchCovidSettlement, fetchSettlementDetails } from '../../utils/fetch'
import DetailsWrapper from '../../components/DetailsWrapper'

import {
  generalKeys,
  conformationKeys,
  migrationKeys,
  materialsKeys,
  servicesKeys,
  risksKeys,
  organizationKeys,
  covidKeys,
} from './labels'

import HouseIcon from './assets/HouseIcon'
import ThreeCircles from './assets/ThreeCircles'
import People from './assets/People'
import Wall from './assets/Wall'
import Warning from './assets/Warning'
import Water from './assets/Water'
import World from './assets/World'
import Covid from './assets/Covid'
// import { covidKeys } from '../CovidDetails/labels'


const dimensions = [
  {
    name: 'Información general',
    keys: generalKeys,
    icon: (<HouseIcon/>)
  },
  {
    name: 'Conformación',
    keys: conformationKeys,
    icon: (<ThreeCircles/>)
  },
  {
    name: 'Migración',
    keys: migrationKeys,
    icon: (<People />)
  },
  {
    name: "Materialidad",
    keys: materialsKeys,
    icon: (<Wall />)
  },
  {
    name: 'Servicios e Infraestructura',
    keys: servicesKeys,
    icon: (<Water />)
  },
  {
    name: 'Riesgos y Vulnerabilidades',
    keys: risksKeys,
    icon: (<Warning />)
  },
  {
    name: 'Organización y participación social',
    keys: organizationKeys,
    icon: (<World/>)
  },
  {
    name: 'Covid-19',
    keys: covidKeys,
    icon: (<Covid />)
  }
]

const SettlementDetails = ({history}) => {

  const context = useContext(Context)
  const { settlementId } = useParams()

  const [dimension, setDimension] = useState(null);
  const [keys, setKeys] = useState(generalKeys);
  const [covidData, handleCovidData] = useState(null);
  
  const handleDimension = (val) => {
    setDimension(val)
  }

  const handleKeys = (keys) => {
    setKeys(keys)
  }

  useEffect(() => {

    if (context.settlements.length > 0 && !context.settlement) {
      const settlement = context.settlements.find(s => s.base_id == settlementId)
      if (settlement) {
        fetchSettlementDetails(settlement.base_id, context.updateSettlement)
        fetchCovidSettlement(settlement.base_settlement_id, handleCovidData)
      } else {
        //TODO fetch settlement manually
      }
    }
  }, [context.settlements])
    
  useEffect(() => {
    if (context.selected !== 'general') {
      context.updateSelected('general')
    }

    if (context.settlement) {
      fetchSettlementDetails(context.settlement.base_id, context.updateSettlement)
      fetchCovidSettlement(context.settlement.base_settlement_id, handleCovidData)
    }
  }, [])

  return (
    <DetailsWrapper
      onClose={() => {
        context.updateSettlement(null)
        context.handleShowMarkerSelected(false)
        context.handleShowPopup(false)
        history.push('/')
      }}     
      dimension={dimension}
      dimensions={dimensions}
      handleDimension={handleDimension} 
      keys={keys}
      handleKeys={handleKeys}
      covidData={covidData && covidData.data}
      settlementData={context.settlement}
    >
    </DetailsWrapper>
  )
}

const BlueCloseWrapper = styled.div`

`

const Wrapper = styled.div`
  z-index: 10;
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  width: 60vw;
  background: white;

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    position: relative;
    width: 100vw;
    height: auto;

    padding: 0;
  }
`

const Heads = styled.div`
  display: flex;
  flex-grow: 1;

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    background: #E6F3F3;
  }
`

const Exit = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    background: #E6F3F3;
    justify-content: center;
  }
`

export default SettlementDetails