import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      fill="none"
      viewBox="0 0 38 38"
    >
      <path
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M19 15.438V8.311M11.875 22.563v-7.125M26.125 22.563v-7.125M19 29.688v-7.125M4.75 15.438h28.5M4.75 22.563h28.5M33.25 8.313H4.75v21.374h28.5V8.313z"
      ></path>
    </svg>
  );
}

export default Icon;
