import React from "react";

function Icon({selected}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={selected ? '#fff' : 'none'}
      viewBox="0 0 32 32"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5.26 6h21.48a1 1 0 01.74 1.673l-8.22 9.04a1 1 0 00-.26.674v7.078a1 1 0 01-.445.832l-4 2.666A1 1 0 0113 27.131v-9.744a1 1 0 00-.26-.673L4.52 7.673A1 1 0 015.26 6v0z"
      ></path>
    </svg>
  );
}

export default Icon;
