import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="49"
      fill="none"
      viewBox="0 0 180 49"
    >
      <path
        fill="#fff"
        d="M156.263 1.005a23.74 23.74 0 00-17.745 7.932V2.74a.7.7 0 00-.239-.531.692.692 0 00-.559-.163h-10.584a.694.694 0 00-.695.694v15.822a.695.695 0 01-.694.695h-10.709a.696.696 0 01-.694-.695V2.745a.695.695 0 00-.694-.694h-10.692a.696.696 0 00-.694.694v8.97c-1.867-2.736-3.844-4.877-5.929-6.426C92.367 2.4 87.47.984 81.537.984c-6.762 0-12.559 2.253-17.391 6.7-3.388 3.124-5.625 6.737-6.71 10.84a.955.955 0 01-.875.695H43.68a.694.694 0 01-.694-.695v-5.203a.694.694 0 01.694-.694h14.58a.694.694 0 00.694-.695l-.028-9.164a.725.725 0 00-.694-.722H.708a.694.694 0 00-.694.694v9.165a.694.694 0 00.694.694h9.234a.694.694 0 01.694.694v33.585a.694.694 0 00.695.694h10.653a.694.694 0 00.694-.694V13.32a.694.694 0 01.694-.694h7.009a.694.694 0 01.694.694v33.612a.694.694 0 00.694.694h26.41a.694.694 0 00.694-.694v-9.279a.694.694 0 00-.694-.694h-14.5a.694.694 0 01-.694-.694v-5.901a.694.694 0 01.694-.694h12.775a.916.916 0 01.85.694A22.587 22.587 0 0063.9 41.91c4.764 4.508 10.625 6.762 17.581 6.762 5.86 0 10.692-1.35 14.556-4.12 2.205-1.62 4.268-3.865 6.189-6.734v9.122a.695.695 0 00.694.694h10.692a.696.696 0 00.694-.694V31.056a.695.695 0 01.694-.695h10.685a.696.696 0 01.694.695v15.85a.695.695 0 00.694.694h10.845a.468.468 0 00.534-.48v-6.449a23.81 23.81 0 0021.39 7.658 23.803 23.803 0 00-3.579-47.324zm-54.037 31.467l-10.414.076a1.694 1.694 0 00-1.138.566c-2.24 2.729-5.381 4.166-9.248 4.166a12.032 12.032 0 01-8.83-3.697c-2.382-2.382-3.545-5.346-3.545-8.887a11.456 11.456 0 013.606-8.563 12.265 12.265 0 018.759-3.472 12.087 12.087 0 019.535 4.412c.303.321.716.515 1.156.542h10.119v14.857zm70.89-7.568h-5.956a1.04 1.04 0 00-1.042 1.042v10.282a1.043 1.043 0 01-1.041 1.041h-16.694a1.04 1.04 0 01-1.041-1.041V25.946a1.043 1.043 0 00-1.041-1.042h-5.475c-.576 0-.722-.347-.326-.756l15.753-16.565a.977.977 0 011.437 0l15.753 16.572c.395.416.249.756-.327.756"
      ></path>
    </svg>
  );
}

export default Icon;
