import React from 'react'
import styled from 'styled-components'
import CategoryTitle from '../CategoryTitle'
import CategoryItem from '../CategoryItem'

const SettlementFilters = ({ hasFilters, cleanFilters, filters, handleFilters, selectedCategories, handleCategory }) => {
  return (
    <React.Fragment> 
      { hasFilters && (
        <CleanWrapper>
          <Clean onClick={cleanFilters}>
            Limpiar filtros
          </Clean>
        </CleanWrapper>
      )}     
      <Category>

        <CategoryTitle 
          title={'Información General'}
          selected={selectedCategories.includes('location')}
          value={'location'}
          categories={selectedCategories}
          onClick={handleCategory}
        />

        { selectedCategories.includes('location') && (
          <div>
            <CategoryItem 
              filter={filters['settlements.country']}
              filters={filters}
              handleFilters={handleFilters}
              label={"Pais"}
              dbKey="settlements.country"
              values={[
                'México',
                'Guatemala',
                'Honduras',
                'Venezuela',
                'Colombia',
              ]}
            /> 

            <CategoryItem 
              filter={filters['settlements.zone']}
              filters={filters}
              handleFilters={handleFilters}
              label={"¿En qué zona de la ciudad se ubica el asentamiento?"}
              dbKey="settlements.zone"
              values={[
                'Casco urbano',
                'Zona periurbana',
                'Zona rural',
                'Reserva ecológica'
              ]}
            /> 

            <CategoryItem 
              filter={filters['settlements.population']}
              filters={filters}
              handleFilters={handleFilters}
              label={"¿Aproximadamente cuántas familias y/o personas viven en el asentamiento?"}
              dbKey="settlements.population"
              values={[
                '8 a 49 familias/35 a 215 personas',
                '50 a 99 familias/215 a 426 personas',
                '100 a 249 familias/403 a 1071 personas',
                '250 a 499 familias/1075 a 2146 personas',
                '500 a 999 familias/2150 a 4296 personas',
                '1000 a 1999 familias/4300 a 8596 personas',
                '+2000 familias/+8600 personas',
                'No sabe',
                'No responde'
              ]}
            /> 
          </div>
        )}

        <CategoryTitle 
          title={'Migración'}
          selected={selectedCategories.includes('migration')}
          value={'migration'}
          categories={selectedCategories}
          onClick={handleCategory}
        />

        { selectedCategories.includes('migration') && (
          <div>
            <CategoryItem 
              filter={filters['settlement_features.migrant_population']}
              filters={filters}
              handleFilters={handleFilters}
              label={"¿De qué países provienen estas personas?"}
              dbKey="settlement_features.migrant_population"
              values={[
                'Venezuela',
                'Colombia',
                'Haití',
                'Nicaragua',
                'Honduras',
                'Guatemala',
                'El Salvador',
                'México',
                'Otro',
                'No sabe'
              ]}
            />

            <CategoryItem 
              filter={filters['settlement_features.country_people_returned']}
              filters={filters}
              handleFilters={handleFilters}
              label={"¿De qué países retornaron estas personas?"}
              dbKey="settlement_features.country_people_returned"
              values={[
                'Estados Unidos',
                'México',
                'Canada',
                'España',
                'Otro',
                'No sabe',
                'No responde'
              ]}
            />
          </div>
        )}

        <CategoryTitle 
          title={'Servicios e Infraestructura'}
          selected={selectedCategories.includes('infrastructure')}
          value={'infrastructure'}
          categories={selectedCategories}
          onClick={handleCategory}
        />

        { selectedCategories.includes('infrastructure') && (
          <div>
            <CategoryItem 
              filter={filters['settlement_features.draining_access']}
              filters={filters}
              handleFilters={handleFilters}
              label={"¿Cuál de estas situaciones, con respecto al drenaje, predomina en la mayoría de las viviendas en el asentamiento?"}
              dbKey="settlement_features.draining_access"
              values={[
                'Conexión al drenaje público',
                'Pozo',
                'Fosa séptica',
                'Drenaje a río, lago o barranco',
                'No hay drenaje, se tira a la calle',
                'Otra',
                'No sabe',
                'No responde'
              ]}
            />

            <CategoryItem 
              filter={filters['settlement_features.water_access']}
              filters={filters}
              handleFilters={handleFilters}
              label={"¿Cómo obtienen el agua para beber?"}
              dbKey="settlement_features.water_access"
              values={[
                'Compra de botellones/garrafones / bolsas con agua.',
                'Agua hervida.',
                'Agua clorada.',
                'Agua de lluvia',
                'Otra',
                'No sabe',
                'No responde'
              ]}
            />

            <CategoryItem 
              filter={filters['settlement_features.energy_access']}
              filters={filters}
              handleFilters={handleFilters}
              label={"¿Cuál de estas situaciones, referentes a la energía eléctrica, predomina en la mayoría de las viviendas en el asentamiento?"}
              dbKey="settlement_features.energy_access"
              values={[
                'Red pública (con medidores en la vivienda)',
                'Red pública (con medidor comunitario)',
                'Conexión irregular a la red pública/pegado al poste',
                'Conexión irregular provista por vecinos de otro asentamiento',
                'Paneles solares',
                'No tienen',
                'Otra',
                'No saben'
              ]}
            />
          </div>
        )}

        <CategoryTitle 
          title={'Riesgos y vulnerabilidades'}
          selected={selectedCategories.includes('risks')}
          value={'risks'}
          categories={selectedCategories}
          onClick={handleCategory}
        />

        { selectedCategories.includes('risks') && (
          <div>
            <CategoryItem 
              filter={filters['settlement_issues.natural_disasters']}
              filters={filters}
              handleFilters={handleFilters}
              label={"¿Cuál o cuáles de los siguientes acontecimientos naturales han ocurrido durante el último año?"}
              dbKey="settlement_issues.natural_disasters"
              values={[
                'Derrumbes',
                'Ciclón/Huracán',
                'Deslaves',
                'Incendio forestal',
                'Inundaciones por lluvia',
                'Inundaciones por cuerpo de agua',
                'Tormentas de nieve',
                'Heladas',
                'Granizadas',
                'Vientos fuertes',
                'Calor Extremo',
                'Sismo',
                'Ninguno',
                'No Sabe',
                'No responde'
              ]}
            />
          </div>
        )}
      </Category>      
    </React.Fragment>
  )

}


const CleanWrapper = styled.div`
  padding: 0 2rem;
`

const Clean = styled.div`
  border-radius: 20px;
  background: white;
  padding: 0.2rem 1rem;
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
  display: inline-block;
`

const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  
`
const FilterLabel = styled.div`
  background: #0785C6;
  border-radius: 20px;
  color: white;
  font-size: 12px;
  padding: 0.5rem 1rem;
  text-align: center;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  display: inline-block;
`

const AppliedFilters = styled.div``

const Category = styled.div`
  padding: 2rem;
  height: 75vh;
  overflow-y: scroll;
  scrollbar-color: ${props => props.theme.colors.primary} RGBA(0, 146, 221, 0.2);
`

export default SettlementFilters