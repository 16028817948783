import React from 'react';
import styled from 'styled-components';
import { clone, flatten } from 'lodash'
import { ResponsivePie } from '@nivo/pie'
import TechoIcon from '../../../Icons/TechoIcon'

const palette = [
  '#231837',
  '#d78e01',
  '#ea6d4f',
  '#0092dd',
  '#00ba88',
  '#6D45BA'
]

const getPieData = (data, total) => {
  return Object.keys(data).map((d, i) => {
    return {
      id: d,
      label: d,
      value: parseInt(data[d] * 100 / total),
      color: palette[i] ? palette[i] : palette[Math.random(5)]
    }
  })
}

const parseKey = (covidData, key, kLabel) => {
 
  const values = covidData.map(cd => cd[key]) 
  let parsedValues = []
  if (!values || !values[0]) {
    return []
  }
  values.forEach(v => {
    const splited = v.split(', ')
    if (splited.length > 0 && splited[0] !== '') {
      parsedValues.push(splited)
    }
  })
  parsedValues = flatten(parsedValues)
  let answers = {}
  let total = 0
  parsedValues.forEach(v => {
    if (answers[v]) {
      let answerValue = answers[v]
      answers = {
        ...clone(answers),
        [v]: answerValue + 1
      }
    } else {
      answers = {
        ...clone(answers),
        [v]: 1
      }
    }
  })

  Object.keys(answers).forEach((valK) => {
    total += answers[valK]
  })

  return [
    {
      title: kLabel,
      answers: answers,
      total: total
    }
  ]
}

const CovidDescription = ({ covidData, keys }) => {
  let firstData = covidData && covidData.length > 0 && covidData[0]
  return (
    <Wrapper>

      {/* general values */}
      <div>
        { firstData && (
          <GeneralData>
            { firstData.country && (
              <GeneralLabelWrapper>
                <GeneralLabel>{'Pais'}</GeneralLabel>
                <GeneralDescription>{firstData.country}</GeneralDescription>
              </GeneralLabelWrapper>
            )}
            { firstData.province && (
              <GeneralLabelWrapper>
                <GeneralLabel>{'Provincia/Departamento'}</GeneralLabel>
                <GeneralDescription>{firstData.province}</GeneralDescription>
              </GeneralLabelWrapper>
            )}
            { firstData.municipality && (
              <GeneralLabelWrapper>
                <GeneralLabel>{'Municipio'}</GeneralLabel>
                <GeneralDescription>{firstData.municipality}</GeneralDescription>
              </GeneralLabelWrapper>
            )}
          </GeneralData>  
        )}        
      </div>


      {keys && Object.keys(keys).map((titleKey) => {
        return (
          <Section>
            <SectionTitle>
              <IconWrapper>
                <TechoIcon />
              </IconWrapper>
              { titleKey }
            </SectionTitle>
            { Object.keys(keys[titleKey]).map((dataKey) => {
              return parseKey(covidData, dataKey, keys[titleKey][dataKey].label).map((parsedKey, index) => {
                return (
                  <LabelWrapper key={index}>
                    <Label>{parsedKey.title}</Label>

                    { keys[titleKey][dataKey].display === 'default' && 
                      Object.keys(parsedKey.answers)
                        .map(ans => 
                          <Description >
                            <p>
                              {ans}
                            </p>                    
                            <Percentage>
                              {parseInt(parsedKey.answers[ans] * 100 / parsedKey.total)}%
                            </Percentage>
                          </Description>
                      )
                    }

                    { keys[titleKey][dataKey].display === 'pie' && 
                      (
                        <>
                        <PieChartWrapper>
                          <ResponsivePie 
                            margin={{ top: 20 , left: 40, right: 40, bottom: 20}}
                            data={getPieData(parsedKey.answers, parsedKey.total)}
                            startAngle={-20}
                            innerRadius={0.5}
                            padAngle={0.7}
                            cornerRadius={1}
                            activeOuterRadiusOffset={8}                            
                            borderWidth={0}
                            borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                            enableArcLinkLabels={false}
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsTextColor="#333"
                            arcLabelsTextColor="#fff"
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{ from: 'color' }}
                            arcLabelsSkipAngle={10}
                            arcLabel={function(e){return e.value + '%'}}    
                            colors={{datum: 'data.color'}}                      
                          />
                        </PieChartWrapper>

                        <LegendWrapper>
                          { getPieData(parsedKey.answers).map(pK => 
                            <DotAnswerWrapper>
                              <Dot color={pK.color}></Dot>
                              <Answer>{pK.label}</Answer>
                            </DotAnswerWrapper>
                          )}
                        </LegendWrapper>

                      </>
                      )                      
                    }
                    
                  </LabelWrapper>
                )
              })
            })}
            
          </Section>
        )
        
      })}
    </Wrapper>
  )
}

const GeneralData = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const GeneralDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: ${props => props.theme.fontSizes.base};
  line-height: 20px;
  color: ${props => props.theme.colors.covid}
`

const GeneralLabelWrapper = styled.div`
  width: 30%;

  padding: 1rem 1rem 1rem 0;

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
  }
  
`

const GeneralLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: ${props => props.theme.fontSizes.sm};
  line-height: 17px;

  margin-bottom: 5px;
`



const SectionTitle = styled.div`
  color: ${props => props.theme.colors.covid};
  font-size: 18px;
  font-weight: 200;
  display: flex;
  align-items: center;  
`

const IconWrapper = styled.div`
  padding-right: 1rem;
  display: flex;
  align-items: center;

  > svg {
    width: 20px;
    height: 20px;
    
    > path {
      fill: ${props => props.theme.colors.covid};
    }
  }
`

const Section = styled.div`
  padding: 2rem 0; 
`

const PieChartWrapper = styled.div`
  display: flex;
  height: 300px;
  width: 30vw;
  overflow: hidden;

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    width: 80vw;
  }
`


const Percentage = styled.div`
  padding: 0.2rem 0.5rem;
  color: white;
  background: ${props => props.theme.colors.covid};
  border-radius: 30px;
  
`

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 2rem;
  padding-bottom: 2rem;
  overflow-x: hidden;
`

const LabelWrapper = styled.div`
  display: block;
  width: 100%;
  max-width: 80%;

  padding: 1rem 1rem 1rem 0;

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
  }
  
`

const Label = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: ${props => props.theme.fontSizes.sm};
  line-height: 17px;

  margin-bottom: 5px;
`

const Description = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: ${props => props.theme.fontSizes.base};
  line-height: 20px;
  color: ${props => props.theme.colors.covid};
  display: flex;
  align-items: center;

  > p {
    margin: 0.5rem;
  }
`

const LegendWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  flex-wrap: wrap;
`

const DotAnswerWrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 0.5rem;
`

const Dot = styled.div`
  width: 18px;
  height: 18px;

  min-width: 18px;
  min-height: 18px;

  border-radius: 50%;
  background: ${props => props.color};

  margin: 0 0.4rem;
`

const Answer = styled.p`
  font-size: 12px;
`

export default CovidDescription;