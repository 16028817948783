import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { Context } from '../../utils/context'

import TechoHeader from '../../components/TechoHeader'
import TileButton from '../../components/TileButton'

import SearchAndFiltersBar from '../../components/SearchAndFiltersBar'
import SearchBar from '../../components/SearchBar'
import DownloadBar from '../../components/DownloadBar'
import Filters from '../../components/Filters'

import Search from '../../components/Icons/Search'
import Funnel from '../../components/Icons/Funnel'
import CloudArrow from '../../components/Icons/CloudArrow'
import Wingu from '../../components/Icons/Wingu'
import Techo from '../../components/Icons/TechoSmall'
import EUAV from '../../components/Icons/EUAV'
import Leadger from '../../components/Icons/Leadger'

const getLastUpdate = (lastUpdate) => {
  if (!lastUpdate || lastUpdate.length === 0) {    
    return 'Censo sin fecha de actualización'
  }

  return `Ultima actualización ${lastUpdate}` 
}


const Home = () => {
  const [barType, setBarType] = useState(null);
  const handleBarShowed = (val) => {
    setBarType(val);
  }

  
  const context = useContext(Context)

  const [filters, handleFilters] = useState({})
  const [selectedCategories, handleCategory] = useState([])
  const [hasFilters, handleHasFilters] = useState(false)

  useEffect(() => {
    // Clear filters sidebar when changing tile
    handleCategory([])
    handleFilters({})
    handleHasFilters(false)
  }, [context.selected])

  const barSelected = (val) => {
    if (val === 'search') { 
      return <SearchBar />
    } else if (val === 'filter') {
      return <Filters 
        selectedCategories={selectedCategories}
        handleCategory={handleCategory}
        handleFilters={handleFilters}
        handleHasFilters={handleHasFilters}
        filters={filters}
        hasFilters={hasFilters}
        selected={context.selected}
        cleanFilters={() => {
          handleCategory([])
          handleFilters({})
          handleHasFilters(false)
        }}
      />
   
    } else if (val === 'download') {
      return <DownloadBar 
        selected={context.selected} 
      />
    }
  }

  return (
    <div>
      {/* Left side  */}
      <div>
        <HeaderWrapper>
          <TechoHeader />          
          <Stats isCovid={context.selected === 'covid'}>
            { context.selected === 'general' ? (
              <p>
                { context.settlements && context.settlements.length } {`Asentamiento`}{context.settlements.length !== 1 && 's'}
              </p>
            ) : (
              <p>
                { context.covidSettls && context.covidSettls.length } {`Asentamiento`}{context.covidSettls.length !== 1 && 's'}
              </p>
            )}

            { context.currentPoll && context.selected === 'general' && (
              <p>
                {getLastUpdate(context.polls.find(p => p.id == context.currentPoll).last_update)}                
              </p>
            )}
          </Stats>
        </HeaderWrapper>

        { context.selected === 'general' && (
          <Years>
          { context.polls.map((p, i) => (
            <Year 
              selected={context.year === p.date} 
              key={i}
              onClick={() => {
                context.updateYear(p.date)
              }}
            >
              <Census>
                Censo
              </Census>
              <div>
                { p.date }
              </div>
            </Year>
            
          ))}
          </Years>
        )}
       
        <Tiles>
          <TileButton 
            selected={context.selected} 
            variant="general"
            onClick={() => { context.updateSelected('general')}}
          >
            Info general
          </TileButton>
          <TileButton 
            selected={context.selected} 
            variant="covid"
            onClick={() => { context.updateSelected('covid')}}
          >
            Covid
          </TileButton>
        </Tiles>

        {/* RightSide */}
        { !!barType
          ? <SearchAndFiltersBar  
              handleBarShowed={handleBarShowed}
            >
              {/* Renders HTML children's conditionally */}
              <div>
                { barSelected(barType) }
              </div>
            </SearchAndFiltersBar>
          : <Options>
              <IconWrapper
                onClick={() => {
                  handleBarShowed('search')
                }}
              >
              <Search />
              <div>
                BUSCAR
              </div>
            </IconWrapper>
            <IconWrapper
              onClick={() => {
                handleBarShowed('filter')
              }}
            >
              <Funnel selected={hasFilters}/>
              <div>
                FILTRAR
              </div>
            </IconWrapper>
            <IconWrapper
              onClick={() => {
                handleBarShowed('download')
              }}
            >
              <CloudArrow />
              <div>
                DESCARGAR
              </div>
            </IconWrapper>
            <Redirect href="https://www.techo.org/techo/" target='_blank'>
              <IconWrapper>
                <Techo />
                <div>
                  ¿QUÉ ES TECHO?
                </div>
              </IconWrapper>
            </Redirect>
            <Redirect href="https://techo.org/sobre-techo/" target='_blank'>
              <IconWrapper>
                <Leadger />
                <div>
                  METODOLOGÍA
                </div>
              </IconWrapper>
            </Redirect>
          </Options>
        }
        <WinguWrapper>
            <span>Desarrollado por</span> 
            <Wingu />
            <span>en colaboración con</span> 
            <EUAV/>
        </WinguWrapper>
      </div>
    </div>    
  )
}

const Years = styled.div`
  position: absolute;
  top: 250px;
  left: 20px;
  padding: 0 2.5rem;
  border-right: 1px solid #fff; 
  display: flex;
  flex-direction: column;
  align-items: center;  
  z-index: 10;

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    border-right: none;
  }
`

const Stats = styled.div`
  padding: 1rem 0 0 2rem;
  > p {
    padding: 0.2rem 0.5rem;
    border-radius: 20px;
    background: #fff;
    color: black;
    font-size: 11px;
    font-weight: 600;
    color: ${props => props.isCovid ? props.theme.colors.covid : props.theme.colors.primary};
  }

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    padding: 1rem 0 0 0.5rem;
  }
`
const Year = styled.div`
  cursor: pointer;
  color: white;
  text-align: center;
  padding: 1rem 0;
  transition: all 0.2s;
  > p {
    margin: 0;
    font-weigth: 400;
    font-size: 12px;
  }
  
  > div {    
    font-size: 22px;
  }
  
  &:hover {
    transform: scale(1.3);
  }

  color: #6BB9EB;

  ${props => props.selected && `
    transform: scale(1.3);
    color: white
  `};

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    border: 1px solid;
    padding: 1rem 3rem;
    border-radius: 50px;
    position: relative;
    right: 90px;

    margin: 15px 0;

    ${props => props.selected && `
      transform: scale(1);
      color: white
    `};

    &:hover {
      transform: scale(1.1);
    }
  }
  
`

const Census = styled.p`
  @media(max-width: ${props => props.theme.breakpoints.sm}) {
    display: none;
  }
`

const WinguWrapper = styled.div`
  z-index: 10;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 2rem;
  display: flex;
  align-items: center;

  > span {
    margin-right: 1rem;
    margin-left: 1rem;
    color: white;
    font-size: 10px;
  }

  > svg, img {
    width: 60px;
  }

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`

const Options = styled.div`
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;  
  padding: 1rem 0;

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
  }
`

const IconWrapper = styled.div`
  z-index: 10;
  padding: 1rem 2rem;
  text-align: center;
  cursor: pointer;

  > svg {
    width: 25px;
    height: 25px;
  }

  > div {
    font-size: 11px;
    color: white;
  }

  @media(max-width: ${props => props.theme.breakpoints.md}) {    
    padding: 2rem 1rem;
    > div {
      display: none;
    }
  }
`

const HeaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`

const Tiles = styled.div`
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2rem;

  > div {
    margin-right: 1rem;
  }

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    padding: 4.2rem 0;
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
  }
`

const Redirect = styled.a`
  text-decoration: none;
`

export default Home