import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M27 27V14.442a1 1 0 00-.327-.74L16.672 4.611a1 1 0 00-1.345 0l-10 9.091a1.001 1.001 0 00-.327.74V27M2 27h28"
      ></path>
      <path
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M19 26.999v-7a1 1 0 00-1-1h-4a1 1 0 00-1 1v7"
      ></path>
    </svg>
  );
}

export default Icon;
