import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="10"
      fill="none"
      viewBox="0 0 18 10"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M16.5 1L9 8.5 1.5 1"
      ></path>
    </svg>
  );
}

export default Icon;
