import React from 'react'
import styled, { withTheme } from 'styled-components'

// Icons 
import MapPin from '../Icons/MapPin'
import Covid from '../Icons/Covid'

const TileButton = ({ children, theme, variant = 'general', selected = false, onClick = () => {} }) => {
  return (
    <Wrapper onClick={onClick}>
      <TileWrapper 
        variant={variant}
        selected={selected}
      >
        <Icon>
          { variant === 'general' && (
            <MapPin color={selected === 'general' ? theme.colors.primary : '#fff'} />
          )}
          { variant === 'covid' && (
            <Covid color={'#fff'} />
          )}
        </Icon>
        <p>
          { children }
        </p>
      </TileWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: inline-block;
`

const TileWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 30px;
  border: 1px solid white;
  padding: 0.5rem 1.2rem;
  font-size: 13px;
  background: transparent;
  cursor: pointer;  
  transition: all 0.2s;

  > p {
    margin: 0;
    color: white;
  }

  ${props => props.variant === 'general' && props.selected === 'general' && `
    background: white;  
    > p {
      color: ${props.theme.colors.primary};
    }
  `};

  ${props => props.variant === 'covid' && props.selected === 'covid' && `
    background: ${props.theme.colors.covid};  
    border: 1px solid ${props.theme.colors.covid};    
  `};
` 

const Icon = styled.div`
  padding-right: 0.3rem;

  > svg {
    width: 22px;
    height: 22px;
  }
`

export default withTheme(TileButton)