import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'

import CovidFilters from './components/CovidFilters'
import SettlementFilters from './components/SettlementFilters'
import { Context } from '../../utils/context'

const fetchFiltered = (filters, context, selected, handleHasFilters) => {
  // create get covid markers query
  const query = Object.keys(filters).map((fk) => {
    return filters[fk].reduce((acc, cur) => acc + (fk + '[]=' + cur + '&'), '')
  }).reduce((acc, cur) => acc + cur, '')

  handleHasFilters(query.length > 0)
  if (selected === 'covid') {
    context.fetchCovidSettls(query, context.updateCovidSettls)
  } else {
    context.fetchSettlements(query + `settlements.poll_id[]=${context.currentPoll}`, context.updateSettlements) 
  }
}



const Filters = ({selected, cleanFilters,  ...props}) => {
  const { filters, handleHasFilters } = props
  const context = useContext(Context)

  useEffect(() => {
    if (Object.keys(filters).length === 0) {
      return
    }

    fetchFiltered(filters, context, selected, handleHasFilters)
  }, [filters])
  
  return (
    <Wrapper>
      <AppliedFilters>
        { Object.keys(filters).map((filterKey, i) => (
          <FiltersLabel>
            {filters[filterKey].map((f) => (
              <FilterLabel>
                { f }
              </FilterLabel>
            ))}
          </FiltersLabel>
        ))}
      </AppliedFilters>


      { selected === 'covid' ? (
        <CovidFilters 
          { ...props }
          cleanFilters={() => {            
            fetchFiltered([], context, selected, handleHasFilters)
            cleanFilters()
          }} 
        />
      ) : (
        <SettlementFilters 
          { ...props } 
          cleanFilters={() => {            
            fetchFiltered([], context, selected, handleHasFilters)
            cleanFilters()
          }} 
        />
      )}
    </Wrapper>
  )
}


const FiltersLabel = styled.div`
  display: flex;
  flex-wrap: wrap;
  
`
const FilterLabel = styled.div`
  background: #0785C6;
  border-radius: 20px;
  color: white;
  font-size: 12px;
  padding: 0.5rem 1rem;
  text-align: center;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  display: inline-block;
`

const AppliedFilters = styled.div`
  padding: 2rem;

`

const Category = styled.div`
  padding-bottom: 1rem;
`

const Wrapper = styled.div`    
`

export default Filters