export const covidKeys = {
  'Diagnóstico de la actualidad': {
    'life_altered': {
      label: '¿Cuánto se ha alterado tu forma de vida después de un año de la llegada del coronavirus?',
      display: 'default'
    },
    'employment_before_quarentine': {
      label: '¿Cuál era tu condición laboral antes de la llegada del coronavirus?',
      display: 'pie'
    },
    'employment_status': {
      label: '¿Cómo es su situación laboral?',
      display: 'default'
    },
    'employment_affected_by_quarentine': {
      label: '¿Cómo se vio afectada tu situación laboral por el COVID-19 y la cuarentena?',
      display: 'pie'
    },
    'difficulties_daily_life': {
      label: '¿Cuáles han sido las principales dificultades que se presentaron en tu vida cotidiana, desde la llegada del coronavirus?',
      display: 'default'
    },
  },

  'Infraestructura': {
    'neighborhood_conditions': {
      label: '¿Qué condiciones del barrio crees que hacen más difícil afrontar al coronavirus?',
      display: 'default'
    },
    'home_situation': {
      label: '¿Crees que la situación de tu casa hace más difícil la capacidad de afrontar el coronavirus?',
      display: 'pie'
    },
    'negatives_home_situations': {
      label: '¿Cuáles de las siguientes situaciones de la vivienda crees que han influído negativamente para afrontar el coronavirus?',
      display: 'default'
    },
  },

  'Elementos de primera necesidad': {
    'neighborhood_daily_situations': {
      label: '¿Qué situaciones de la vida cotidiana del barrio han impedido que cumplan con las medidas promovidas para prevenir el coronavirus?',
      display: 'default'
    },
    'main_family_needs': {
      label: '¿Cuáles son las principales necesidades de tu grupo familiar en este contexto?',
      display: 'default'
    }
  },
  
  'Protocolos estatales de prevención': {
    'government_measures': {
      label: '¿Sabes si el gobierno nacional ha tomado medidas específicas para asistir a los asentamientos populares?',
      display: 'pie'
    }
  },

  'Propuestas de asistencia para población de barrios populares': {
    'promoted_measures_applied': {
      label: '¿Cuáles de las siguientes medidas promovidas por el gobierno se están cumpliendo en el barrio popular?',
      display: 'default'
    },
    'are_benefits_received': {
      label: '¿Recibieron alguno los beneficios mencionados recién?',
      display: 'pie'
    },
    'what_benefits': {
      label: '¿Cuáles de los beneficios mencionados recién recibieron?',
      display: 'default'
    },
    'difficulties_accessing_benefits': {
      label: '¿Ha tenido dificultades para acceder a este beneficio?',
      display: 'pie'
    }
  },

  'Capacidades Comunitarias': {
    'activities_developed_frequently': {
      label: '¿Qué actividades se han desarrollado con mayor frecuencia en este año, para enfrentar las consecuencias del coronavirus entre las familias del barrio?',
      display: 'default'
    },
    'main_need_to_organize': {
      label: '¿Cuál creés que es/son la/s principal/es necesidad/es para organizarse en el barrio junto a los/as vecinos/as? Sin importar si ya se hizo una actividad que atienda ese problema',
      display: 'pie'
    },
    'neighborhood_strengths': {
      label: '¿Con qué fortalezas crees que cuenta actualmente el asentamiento para enfrentar esta situación?',
      display: 'default'
    },
    'who_visited_neighborhood': {
      label: '¿Quiénes se han acercado al asentamiento en estas últimas semanas?',
      display: 'pie' 
    }
  }
}