import React from "react";

function Icon({color = '#0092dd'}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5 16h22M16 5v22"
      ></path>
    </svg>
  );
}

export default Icon;
