import React, { useContext } from 'react';
import styled from 'styled-components';

import { Context } from '../../utils/context'

import Twitter from '../Icons/Twitter';
import Facebook from '../Icons/Facebook';
import Whatsapp from '../Icons/Whatsapp';

const SocialMedia = ({ covidInfo }) => {

  const share = () => {
    const facebookBtn = document.getElementById("facebook-btn");
    const twitterBtn = document.getElementById("twitter-btn");
    const whatsappBtn = document.getElementById("whatsapp-btn");

    let postUrl = encodeURI(document.location.href);
    let postTitle = encodeURI(`Aquí encontrarás detalles sobre el asentamiento ${covidInfo.name}, ubicado en ${covidInfo.province}, ${covidInfo.country}.`)

    facebookBtn.setAttribute(
      "href",
      `https://www.facebook.com/sharer.php?u=${postUrl}`
    );

    twitterBtn.setAttribute(
      "href",
      `https://twitter.com/share?url=${postUrl}&text=${postTitle}`
    );

    whatsappBtn.setAttribute(
      "href",
      `https://wa.me/?text=${postTitle} ${postUrl}`
    );
  }

  return (
    <MediaWrapper>
      <Share>Compartir</Share>

      <a id='twitter-btn' target='_blank'>
        <IconWrapper 
          onClick={() => share()}
        >
          <Twitter />
        </IconWrapper>
      </a>

      <a id='facebook-btn' target='_blank'>
        <IconWrapper 
          onClick={() => share()}
        >
          <Facebook />
        </IconWrapper>
      </a>

      <a id='whatsapp-btn' target='_blank'>
        <IconWrapper 
          onClick={() => share()}
        >
          <Whatsapp />
        </IconWrapper>
      </a>
    </MediaWrapper>
  )
}

const MediaWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  width: 40vw;
  min-height: 15vh;

  margin: 0 2rem;

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;

    margin: 0;
    justify-content: center;
  }

`

const IconWrapper = styled.div`
  margin: 0 1rem;

  cursor: pointer;
`

const Share = styled.div`
  font-size: ${props => props.theme.fontSizes.base}
`

export default SocialMedia;