import React, { useState } from 'react';
import styled from 'styled-components';


const Dimensions = ({ hasCovid, handleDimension, handleKeys, dimensions = [],  }) => {
  const [selected, setSelected] = useState(dimensions[0].name);
  const [keys, setKeys] = useState(dimensions[0].keys);

  const handleSelected = (val, keys) => {
    setSelected(val);
    setKeys(keys);
  }

  if(selected) handleDimension && handleDimension(selected);
  if(keys) handleKeys && handleKeys(keys);

  return (
    <SvgWrapper>

      { dimensions.map((d, i) => (
        <DimensionWrapper 
          onClick={() => {
            if (d.name === 'Covid-19' && !hasCovid) {
              return false
            }
            handleSelected(d.name, d.keys)
          }}
          selected={selected}
          variant={d.name}
        > 
          <Icon disabled={d.name === 'Covid-19' && !hasCovid}>
            { d.icon }
          </Icon>
          <Text disabled={d.name === 'Covid-19' && !hasCovid}>{d.name}</Text>
        </DimensionWrapper>
      ))}      
    </SvgWrapper>
  )
}

const SvgWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;  

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    background: #E6F3F3;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`

const DimensionWrapper = styled.div`
  text-align: center;
  cursor: pointer;
  width: 100px;


  ${props => props.selected === props.variant && props.selected !== 'Covid-19' &&`
    color: ${props.theme.colors.primary};
    border-bottom: medium solid ${props.theme.colors.primary};
  `}

  ${props => props.selected === props.variant && props.selected === 'Covid-19' && `
      color: ${props.theme.colors.covid};
      border-bottom: medium solid ${props.theme.colors.covid};
    `}
  
  @media(max-width: ${props => props.theme.breakpoints.md}) {
    width: 33%;
    padding: 0;
    margin: 1rem 0;
  }
`

const Text = styled.p`
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizes.xxs};

  ${props => props.disabled && `
    color: #dedede;
  `}

`

const CovidText = styled.p`
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizes.xxs};
  color: ${props => props.theme.colors.covid};

`

const Icon = styled.div`
  max-height: 35px;

  ${props => props.disabled && `
    > svg {
      > path {
        stroke: #dedede;
      }
      > circle {
        stroke: #dedede;
      }
    }
  `}
`

export default Dimensions;
