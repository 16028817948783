export const generalKeys = {
  'country': 'País',
  'province': 'Provincia / Departamento en la que vives',
  'municipality': 'Municipalidad',
  'zone': '¿En qué zona de la ciudad se ubica el asentamiento?',
  'population': '¿Aproximadamente cuántas familias y/o personas vivien en el asentamiento?'
}

export const conformationKeys = {
  'settlement_start': '¿De qué manera se formó/constituyó el asentamiento?'
}

export const migrationKeys = {
  'migrant_population': '¿De qué paises provienen estas personas?',
  'returned_migrants': '¿Existen en el asentamiento personas que sean migrantes retornados?',
  'country_people_returned': '¿De qué países retornaron estas personas?',
  'migrant_type': 'De las personas migrantes ¿Cuál es la composición familiar de las personas que  se han ido en el último año?',
}

export const materialsKeys = {
  'walls_material': '¿Cuál es el materíal principal de LOS MUROS de la MAYORÍA de las casas de la asentamiento?',
  'roofs_material': '¿Cuál es el material principal de LOS TECHOS de la MAYORÍA de la asentamiento?', 
  'floors_material': '¿Cuál es el material principal de LOS PISOS/SUELOS de la MAYORÍA de las casas del asentamiento?'
}

export const servicesKeys = {
  'water_access': '¿Cómo obtienen el agua para beber?',
  'energy_access': '¿Cuál de estas situaciones, referentes a la energía eléctrica, predomina en la mayoría de las viviendas en el asentamiento?',
  'draining_access': '¿Cuál de estas situaciones, con respecto al drenaje, predomina en la mayoría de las viviendas en el asentamiento?',
  'waste_access': 'El camión de la basura pasa por...',
  'public_light_access': '¿El asentamiento cuenta con alumbrado público?',
  'streets_material': '¿cuál es el principal material de las calles?',
  'kindergarten_access': 'De la entrada de la asentamiento ¿A qué distancia se encuentra jardín de niños más cercano?',
  'primary_school_access': 'De la entrada de la asentamiento ¿A qué distancia se encuentrala la escuela primaria más cercana?',
  'secondary_school_access': 'De la entrada de la asentamiento ¿A qué distancia se encuentra la instituto (escuela secundaria) más cercana?',
  'parks_access': 'De la entrada de la asentamiento ¿A qué distancia se encuentra el parque,cancha, centro de deportes, espacio recreativo o cultural más cercano?',
  'police_access': 'De la entrada de la asentamiento ¿A qué distancia se encuentra la estación de policía más cercana?',
  'firestation_access': 'De la entrada de la asentamiento ¿A qué distancia se encuentra la estación de bomberos más cercana?',
  'hospital_access': 'De la entrada de la asentamiento ¿A qué distancia se encuentra la clínica de salud pública más cercana?'
}

export const risksKeys = {
  'natural_disasters': '¿Cuál o cuáles de los siguientes acontecimientos naturales han ocurrido durante el último año?',
  'settlement_limits': '¿Qué se encuentra a menos de 10 minutos sobre los límites del asentamiento? (Entorno)',
  // 'asd': '¿Qué problemáticas identifica en la comunidad?',
  'bad_roads': '¿Qué problemáticas identifica en la comunidad?/Caminos en mal estado',
  'bad_housing': '¿Qué problemáticas identifica en la comunidad?/Viviendas en malas condiciones',
  'housing_fires': '¿Qué problemáticas identifica en la comunidad?/Incendios en viviendas',
  'insecurity': '¿Qué problemáticas identifica en la comunidad?/Inseguridad/Delincuencia',
  'drugs': '¿Qué problemáticas identifica en la comunidad?/Drogas',
  'alcoholism': '¿Qué problemáticas identifica en la comunidad?/Alcoholismo',
  'unemployment': '¿Qué problemáticas identifica en la comunidad?/Desempleo',
  'violence': '¿Qué problemáticas identifica en la comunidad?/Violencia intrafamiliar',
  'insalubrity': '¿Qué problemáticas identifica en la comunidad?/Insalubridad',
  'murder': '¿Qué problemáticas identifica en la comunidad?/Homicidios o asesinatos',
  'rape': '¿Qué problemáticas identifica en la comunidad?/Violaciones',
  'kidnapping': '¿Qué problemáticas identifica en la comunidad?/Secuestros',
  'assault': '¿Qué problemáticas identifica en la comunidad?/Asaltos',
  'fighting': '¿Qué problemáticas identifica en la comunidad?/Enfrentamientos físico o verbales',
  'none': '¿Qué problemáticas identifica en la comunidad?/Ninguna',
  'property_damage': '¿Qué problemáticas identifica en la comunidad?/Destrucción de propiedad privada/pública'
}

export const organizationKeys = {
  'local_committee': '¿Qué organizaciones comunitarias hay en el asentamiento?/Asociación vecinal',
  'religious_group': '¿Qué organizaciones comunitarias hay en el asentamiento?/Grupo religioso',
  'sports_team': '¿Qué organizaciones comunitarias hay en el asentamiento?/Equipo deportivo',
  'cultural_group': '¿Qué organizaciones comunitarias hay en el asentamiento?/Grupos culturales o recreativos',
  'cooperatives': '¿Qué organizaciones comunitarias hay en el asentamiento?/Cooperativas',
  'no_answer': '¿Qué organizaciones comunitarias hay en el asentamiento?/No responde',
  'none': '¿Qué organizaciones comunitarias hay en el asentamiento?/No hay ningún tipo de organización comunitaria'
}

export const covidKeys = {
  'Diagnóstico de la actualidad': {
    'life_altered': {
      label: '¿Cuánto se ha alterado tu forma de vida después de un año de la llegada del coronavirus?',
      display: 'default'
    },
    'employment_before_quarentine': {
      label: '¿Cuál era tu condición laboral antes de la llegada del coronavirus?',
      display: 'pie'
    },
    'employment_status': {
      label: '¿Cómo es su situación laboral?',
      display: 'default'
    },
    'employment_affected_by_quarentine': {
      label: '¿Cómo se vio afectada tu situación laboral por el COVID-19 y la cuarentena?',
      display: 'pie'
    },
    'difficulties_daily_life': {
      label: '¿Cuáles han sido las principales dificultades que se presentaron en tu vida cotidiana, desde la llegada del coronavirus?',
      display: 'default'
    },
  },

  'Infraestructura': {
    'neighborhood_conditions': {
      label: '¿Qué condiciones del barrio crees que hacen más difícil afrontar al coronavirus?',
      display: 'default'
    },
    'home_situation': {
      label: '¿Crees que la situación de tu casa hace más difícil la capacidad de afrontar el coronavirus?',
      display: 'pie'
    },
    'negatives_home_situations': {
      label: '¿Cuáles de las siguientes situaciones de la vivienda crees que han influído negativamente para afrontar el coronavirus?',
      display: 'default'
    },
  },

  'Elementos de primera necesidad': {
    'neighborhood_daily_situations': {
      label: '¿Qué situaciones de la vida cotidiana del barrio han impedido que cumplan con las medidas promovidas para prevenir el coronavirus?',
      display: 'default'
    },
    'main_family_needs': {
      label: '¿Cuáles son las principales necesidades de tu grupo familiar en este contexto?',
      display: 'default'
    }
  },
  
  'Protocolos estatales de prevención': {
    'government_measures': {
      label: '¿Sabes si el gobierno nacional ha tomado medidas específicas para asistir a los asentamientos populares?',
      display: 'pie'
    }
  },

  'Propuestas de asistencia para población de barrios populares': {
    'promoted_measures_applied': {
      label: '¿Cuáles de las siguientes medidas promovidas por el gobierno se están cumpliendo en el barrio popular?',
      display: 'default'
    },
    'are_benefits_received': {
      label: '¿Recibieron alguno los beneficios mencionados recién?',
      display: 'pie'
    },
    'what_benefits': {
      label: '¿Cuáles de los beneficios mencionados recién recibieron?',
      display: 'default'
    },
    'difficulties_accessing_benefits': {
      label: '¿Ha tenido dificultades para acceder a este beneficio?',
      display: 'pie'
    }
  },

  'Capacidades Comunitarias': {
    'activities_developed_frequently': {
      label: '¿Qué actividades se han desarrollado con mayor frecuencia en este año, para enfrentar las consecuencias del coronavirus entre las familias del barrio?',
      display: 'default'
    },
    'main_need_to_organize': {
      label: '¿Cuál creés que es/son la/s principal/es necesidad/es para organizarse en el barrio junto a los/as vecinos/as? Sin importar si ya se hizo una actividad que atienda ese problema',
      display: 'pie'
    },
    'neighborhood_strengths': {
      label: '¿Con qué fortalezas crees que cuenta actualmente el asentamiento para enfrentar esta situación?',
      display: 'default'
    },
    'who_visited_neighborhood': {
      label: '¿Quiénes se han acercado al asentamiento en estas últimas semanas?',
      display: 'pie' 
    }
  }
}