import React from 'react';
import styled from 'styled-components';

const parseValue = (value) => {
  if (typeof value === 'boolean') {
    return value ? 'Si' : 'No'
  }

  return value
}
const SettlementDescription = ({ settlement, keys }) => {

  return (
    <Wrapper>
      {keys && Object.keys(keys)
        // .filter(k => settlement[k])
        .map(k => 
          <LabelWrapper key={k}>
            <Label>{keys[k]}</Label>
            <Description>{parseValue(settlement[k])}</Description>
          </LabelWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  padding: 0 2rem;
  padding-bottom: 2rem;
`

const LabelWrapper = styled.div`
  width: 30%;

  padding: 1rem 1rem 1rem 0;

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
  }
  
`

const Label = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: ${props => props.theme.fontSizes.sm};
  line-height: 17px;

  margin-bottom: 5px;
`

const Description = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: ${props => props.theme.fontSizes.base};
  line-height: 20px;
  color: ${props => props.theme.colors.primary}
`

export default SettlementDescription;