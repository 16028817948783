
import axios from 'axios';

const jsonToCsv = (data, fileName) => {
    // convert JSON to CSV
    const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    const header = Object.keys(data[0])
    let csv = data.map(row => header.map(fieldName => 
    JSON.stringify(row[fieldName], replacer)).join(','))
    csv.unshift(header.join(','))
    csv = csv.join('\r\n')

    // Create link and download
    let link = document.createElement('a');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const downloadGeneral = async(pollId, year, updateDownloadStatus) => {

  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/settlements/export?poll=${pollId}`);

    let { data } = res;
    let fileName = `Asentamientos general. Año: ${year}`

    updateDownloadStatus(data.name)

    jsonToCsv(data, fileName);

    

  } catch (err) {
    console.log(err)
  }
}


export const downloadCovid = async() => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/covid/export`);

    let { data } = res.data;
    let fileName = 'Asentamientos COVID';

    jsonToCsv(data, fileName);

  } catch (err) {
    console.log(err)
  }
}