
import React from "react";

function Icon() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.74978 28.125C6.74978 28.125 12.3748 27 13.4998 23.625C13.4998 23.625 4.49978 20.25 6.74978 7.87501C6.74978 7.87501 11.2498 13.5 17.9998 14.625V12.3754C17.9999 11.083 18.445 9.83006 19.2602 8.82721C20.0754 7.82436 21.2111 7.13276 22.4762 6.86869C23.7414 6.60463 25.0588 6.7842 26.2072 7.37721C27.3555 7.97022 28.2646 8.94051 28.7817 10.125L33.7498 10.125L29.2498 14.625C29.2498 22.5 23.6248 30.375 13.4998 30.375C8.99978 30.375 6.74978 28.125 6.74978 28.125Z" stroke="#0092DD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default Icon;