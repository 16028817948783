import React from 'react';
import styled from 'styled-components';
import Result from '../Result'

const Results = ({ settlements, type }) => {
  return (
    <>
      {settlements ? settlements.map(settl => 
        <Wrapper key={settl.name}>
          <Result type={type} settlement={settl} />
        </Wrapper>
      ) : false}
    </>
  )
}

const Wrapper = styled.div`
  cursor: pointer;
`;

export default Results;