import React from "react";

function Icon({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      fill="none"
      viewBox="0 0 29 29"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
        d="M14.5 2.862V1M13.103 1h2.793M14.5 26.138V28M15.897 28h-2.793M26.138 14.5H28M28 13.103v2.793M2.862 14.5H1M1 15.897v-2.793M8.681 4.421l-.93-1.613M6.54 3.507L8.96 2.11M20.319 24.579l.93 1.613M22.46 25.493l-2.42 1.397M24.579 8.681l1.612-.93M25.493 6.54l1.396 2.42M4.421 20.319l-1.612.93M3.507 22.46l-1.396-2.42M4.421 8.68L2.81 7.75M2.11 8.96l1.397-2.42M24.578 20.32l1.613.93M26.89 20.04l-1.397 2.42M20.319 4.421l.93-1.612M20.04 2.11l2.42 1.397M8.681 24.578l-.93 1.613M8.96 26.89l-2.42-1.397"
      ></path>
      <circle
        cx="14.5"
        cy="14.5"
        r="10.988"
        stroke={color}
        strokeWidth="1.3"
      ></circle>
      <circle
        cx="14.034"
        cy="14.034"
        r="1.212"
        stroke={color}
        strokeWidth="1.3"
      ></circle>
      <circle
        cx="14.034"
        cy="7.517"
        r="1.212"
        stroke={color}
        strokeWidth="1.3"
      ></circle>
      <circle
        cx="14.966"
        cy="20.552"
        r="2.143"
        stroke={color}
        strokeWidth="1.3"
      ></circle>
      <circle
        cx="8.856"
        cy="11.241"
        r="0.883"
        stroke={color}
        strokeWidth="1.3"
        transform="rotate(-60 8.856 11.241)"
      ></circle>
      <ellipse
        cx="20.846"
        cy="17.07"
        stroke={color}
        strokeWidth="1.3"
        rx="1.675"
        ry="1.675"
        transform="rotate(-60 20.846 17.07)"
      ></ellipse>
      <mask id="path-31-inside-1" fill="#fff">
        <ellipse
          cx="8.856"
          cy="17.759"
          rx="1.259"
          ry="1.259"
          transform="rotate(-120 8.856 17.759)"
        ></ellipse>
      </mask>
      <path
        fill={color}
        d="M8.876 17.794a.046.046 0 01-.03.004.046.046 0 01-.025-.019l2.252-1.3a2.56 2.56 0 00-3.497-.937l1.3 2.252zm-.055-.015a.045.045 0 01-.004-.031.04.04 0 01.007-.013.047.047 0 01.012-.012l1.3 2.252a2.56 2.56 0 00.937-3.496l-2.252 1.3zm.015-.056a.046.046 0 01.03-.004.046.046 0 01.025.019l-2.251 1.3a2.56 2.56 0 003.496.937l-1.3-2.252zm.055.015a.045.045 0 01.004.031.047.047 0 01-.019.025l-1.3-2.252a2.56 2.56 0 00-.936 3.496l2.251-1.3z"
        mask="url(#path-31-inside-1)"
      ></path>
      <path
        stroke={color}
        strokeWidth="1.3"
        d="M19.305 9.789a1.678 1.678 0 111.678 2.905 1.678 1.678 0 01-1.678-2.905z"
      ></path>
    </svg>
  );
}

export default Icon;
