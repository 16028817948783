import React from 'react';
import styled from 'styled-components';

import CloseIcon from '../Icons/Close';

const CloseButton = ({handleBarShowed}) => {
  return (
    <Wrapper onClick={() =>
      // This is setted on barType and will close the bar/filter/download bar being displayed. 
      // Check setBarType in Home component.
      // Also check the ternary being used in elements being rendered.
      handleBarShowed(null)
    }>
      <CloseIcon />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 50px;
  font-weight: 100;
  text-align: center;

  cursor: pointer;

  color: white;

  padding: 1rem 0;
`

export default CloseButton;