
import React from "react";

function Icon() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.38842 24.8904C4.71114 22.0629 4.12374 18.7204 4.73652 15.4904C5.34931 12.2605 7.12013 9.36534 9.71646 7.34863C12.3128 5.33193 15.556 4.33239 18.8372 4.53771C22.1183 4.74304 25.2116 6.1391 27.5363 8.46374C29.861 10.7884 31.257 13.8817 31.4624 17.1629C31.6677 20.444 30.6682 23.6872 28.6515 26.2836C26.6348 28.8799 23.7397 30.6507 20.5097 31.2636C17.2798 31.8764 13.9372 31.289 11.1097 29.6117L11.1098 29.6116L6.44712 30.9437C6.25422 30.9989 6.05008 31.0014 5.85586 30.9511C5.66165 30.9007 5.48443 30.7994 5.34256 30.6575C5.20069 30.5157 5.09935 30.3384 5.04902 30.1442C4.9987 29.95 5.00122 29.7459 5.05634 29.553L6.38853 24.8903L6.38842 24.8904Z" stroke="#0092DD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21.391 25.875C19.9109 25.8771 18.445 25.5871 17.0772 25.0217C15.7094 24.4563 14.4666 23.6265 13.42 22.58C12.3735 21.5334 11.5437 20.2906 10.9783 18.9228C10.4129 17.555 10.1229 16.0891 10.125 14.609C10.1284 13.5672 10.5448 12.5693 11.2829 11.8341C12.0211 11.099 13.0207 10.6866 14.0625 10.6875V10.6875C14.2334 10.6875 14.4012 10.7327 14.549 10.8184C14.6968 10.9042 14.8193 11.0275 14.9041 11.1759L16.5481 14.053C16.6473 14.2265 16.6984 14.4233 16.6963 14.6231C16.6942 14.8229 16.6388 15.0186 16.536 15.1899L15.2157 17.3905C15.895 18.898 17.102 20.105 18.6095 20.7843V20.7843L20.8101 19.464C20.9814 19.3612 21.1771 19.3058 21.3769 19.3037C21.5767 19.3016 21.7735 19.3527 21.947 19.4519L24.8241 21.0959C24.9725 21.1807 25.0958 21.3032 25.1816 21.451C25.2673 21.5988 25.3125 21.7666 25.3125 21.9375V21.9375C25.3095 22.9781 24.8959 23.9755 24.1616 24.7128C23.4273 25.4501 22.4316 25.8678 21.391 25.875V25.875Z" stroke="#0092DD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default Icon;