import React, { useState, useContext } from 'react';
import { Context } from '../../utils/context';

import Input from '../Input'
import Results from './Results'

const SearchBar = () => {

  const context = useContext(Context)
  const [settlements, setSettlements] = useState(null);
  const handleSettlements = (val) => {
    setSettlements(val)
  }
  
  let filteredSettls= [];
  if(settlements) {
    filteredSettls = settlements.filter(settl => settl.latitude !== "" && settl.longitude !== "")
  }

  return (
    <>
      <Input handleSettlements={handleSettlements} type={context.selected} />
      <Results settlements={filteredSettls} type={context.selected}/>
    </>
  )
}

export default SearchBar;