import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M18 31.5c7.456 0 13.5-6.044 13.5-13.5S25.456 4.5 18 4.5 4.5 10.544 4.5 18 10.544 31.5 18 31.5z"
      ></path>
      <path
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M30.117 23.959l-7.634-4.696a1.125 1.125 0 00-.44-.156l-3.208-.433a1.125 1.125 0 00-1.178.657l-1.926 4.323a1.125 1.125 0 00.201 1.221l2.644 2.86a1.125 1.125 0 01.279.977l-.54 2.784"
      ></path>
      <path
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9.134 7.82l-1.258 2.974a1.125 1.125 0 00-.017.833l1.616 4.312a1.124 1.124 0 00.817.706l3.013.647a1.125 1.125 0 01.776.61l.536 1.106a1.124 1.124 0 001.012.634h1.89"
      ></path>
      <path
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M21.44 4.943l1.312 2.35a1.125 1.125 0 01-.228 1.384l-3.784 3.422a1.124 1.124 0 01-.211.15l-1.721.951c-.167.092-.354.14-.544.14h-3.002a1.125 1.125 0 00-1.037.689l-1.17 2.78"
      ></path>
    </svg>
  );
}

export default Icon;
