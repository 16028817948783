import React from 'react'
import styled from 'styled-components'

import CategoryTitle from '../CategoryTitle'
import CategoryItem from '../CategoryItem'

const CovidFilters = ({ hasFilters, cleanFilters, filters, handleFilters, selectedCategories, handleCategory }) => {
  
  return (
    <React.Fragment> 
      { hasFilters && (
        <CleanWrapper>
          <Clean onClick={cleanFilters}>
            Limpiar filtros
          </Clean>
        </CleanWrapper>
      )}  
      <Category>
        <CategoryTitle 
          title={'Información General'}
          selected={selectedCategories.includes('location')}
          value={'location'}
          categories={selectedCategories}
          onClick={handleCategory}
        />

        { selectedCategories.includes('location') && (
          <div>
            <CategoryItem 
              filter={filters['covid.country']}
              filters={filters}
              handleFilters={handleFilters}
              label={"Pais"}
              dbKey="covid.country"
              values={[
                'Argentina',
                'Chile',
                'Colombia',
                'Ecuador',
                'El Salvador',
                'Guatemala',
                'Panamá',
                'Paraguay',
                'Perú',
                'República Dominicana',
                'Uruguay',
                'Venezuela',
              ]}
            /> 
          </div>
        )}


        <CategoryTitle 
          title={'Diagnóstico de la actualidad'}
          selected={selectedCategories.includes('present')}
          value={'present'}
          categories={selectedCategories}
          onClick={handleCategory}
        />

        { selectedCategories.includes('present') && (
          <div>
            <CategoryItem 
              filter={filters['covid.difficulties_daily_life']}
              filters={filters}
              handleFilters={handleFilters}
              label={'¿Cuáles han sido las principales dificultades que se han presentado en su vida cotidiana, desde la llegada del coronavirus?'}
              dbKey="covid.difficulties_daily_life"
              values={[
                'Disminución de salario o ingreso del hogar',
                'Imposibilidad de obtener suficiente comida para el hogar',
                'Dificultad para ir al doctor o al consultorio cuando ha sido necesario',
                'Obligación de quedarse en la casa',
                'Obligación de educar a los niños en la casa',
                'Alguien ha perdido su trabajo o no ha podido trabajar como lo hacía antes',
                'Otra_________Cuál',
                'NS/NR',
                'Ninguna de las anteriores'
              ]}
            /> 

            {/* <CategoryItem 
              filter={filters['covid.neighborhood_daily_situations']}
              filters={filters}
              handleFilters={handleFilters}
              label={'¿Qué situaciones de la vida cotidiana del barrio han impedido que cumplan con las medidas promovidas para prevenir el coronavirus?'}
              dbKey="covid.neighborhood_daily_situations"
              values={[
                'Quedarse en casa',
                'Disminución de actividad productiva',
                'Desabastecimiento de despensa',
                'Falta de agua para lavarse las manos con agua y jabón frecuentemente.',
                'Falta de recursos para adquirir productos de limpieza',
                'Otros',
                'NS/NR',
                'Ninguna de las anteriores'
              ]}
            />  */}

            <CategoryItem 
              filter={filters['covid.main_family_needs']}
              filters={filters}
              handleFilters={handleFilters}
              label={'¿Cuáles son las principales necesidades de tu grupo familiar en este contexto?'}
              dbKey="covid.main_family_needs"
              values={[
                'Alimentos',
                'Elementos de limpieza',
                'Medicamentos',
                'Agua Potable',
                'Vestimenta',
                'Trabajo',
                'Ingresos',
                'Otra___________¿cual?',
                'NS/NR',
                'Ninguna de las anteriores'
              ]}
            /> 
          </div>
        )}

        <CategoryTitle 
          title={'Infraestructura'}
          selected={selectedCategories.includes('infrastructure')}
          value={'infrastructure'}
          categories={selectedCategories}
          onClick={handleCategory}
        />

        { selectedCategories.includes('infrastructure') && (
          <div>
            <CategoryItem 
              filter={filters['covid.neighborhood_conditions']}
              filters={filters}
              handleFilters={handleFilters}
              label={"¿Qué condiciones del barrio crees que hacen más difícil afrontar al coronavirus?"}
              dbKey="covid.neighborhood_conditions"
              values={[
                'Falta de agua potable',
                'Falta de energía eléctrica',
                'Problemas de desagüe',
                'Falta de baños/saneamiento',
                'Falta de espacios comunitarios',
                'Falta de iluminación pública',
                'Otros________Cuál',
                'NS/NR',
                'Ninguna de las anteriores'
              ]}
            /> 

            <CategoryItem 
              filter={filters['covid.home_situation']}
              filters={filters}
              handleFilters={handleFilters}
              label={"¿Crees que la situación de tu casa hace más difícil la capacidad de afrontar el coronavirus?"}
              dbKey="covid.home_situation"
              values={[
                'Totalmente',
                'Mucho / Bastante',
                'Ni mucho, ni poco',
                'Poco',
                'Nada'
              ]}
            />
          </div>
        )}

        <CategoryTitle 
          title={'Protocolos estatales de prevención'}
          selected={selectedCategories.includes('protocols')}
          value={'protocols'}
          categories={selectedCategories}
          onClick={handleCategory}
        />

        { selectedCategories.includes('protocols') && (
          <div>
            <CategoryItem 
              filter={filters['covid.government_measures']}
              filters={filters}
              handleFilters={handleFilters}
              label={"¿Sabes si el gobierno nacional ha tomado medidas para asistir a los asentamientos populares?"}
              dbKey="covid.government_measures"
              values={[
                'Sí',
                'No'
              ]}
            /> 

            <CategoryItem 
              filter={filters['covid.difficulties_accessing_benefits']}
              filters={filters}
              handleFilters={handleFilters}
              label={"¿Ha tenido dificultades para acceder a este beneficio?"}
              dbKey="covid.difficulties_accessing_benefits"
              values={[
                'Si',
                'No'
              ]}
            /> 
          </div>
        )}

        {/* <CategoryTitle 
          title={'Capacidades comunitarias'}
          selected={selectedCategories.includes('community_capabilities')}
          value={'community_capabilities'}
          categories={selectedCategories}
          onClick={handleCategory}
        />

        { selectedCategories.includes('community_capabilities') && (
          <div>
            <CategoryItem 
              filter={filters['covid.activities_developed_frequently']}
              filters={filters}
              handleFilters={handleFilters}
              label={"¿Qué actividades se han desarrollado con mayor frecuencia en este año, para enfrentar las consecuencias del coronavirus entre las familias del barrio?"}
              dbKey="covid.activities_developed_frequently"
              values={[
                'Organizar ollas comunitarias',
                'Organizar jornadas de limpieza',
                'Organizar búsqueda de alimentos',
                'Coordinar con otras organizaciones y personas la recepción y entrega de alimentos y/o elementos de limpieza.',
                'Levantar censo comunitario',
                'Identiificar a las personas más vulnerables (ancianos, enfermos)',
                'Distribuir información sobre cómo prevenir el coronavirus',
                'Otra_________Cuál'
              ]}
            />

            <CategoryItem 
              filter={filters['covid.who_visited_neighborhood']}
              filters={filters}
              handleFilters={handleFilters}
              label={"¿Quiénes se han acercado al asentamiento en estas últimas semanas?"}
              dbKey="covid.who_visited_neighborhood"
              values={[
                'Personas individuales',
                'Empresas',
                'Cruz Roja',
                'La iglesia',
                'La municipalidad',
                'La policía',
                'Organizaciones Sociales',
                'Otras organzicaciones______Cuáles'
              ]}
            /> 
          </div>
        )} */}

      </Category>      
    </React.Fragment>
  )

}

const CleanWrapper = styled.div`
  padding: 0 2rem;
`

const Clean = styled.div`
  border-radius: 20px;
  background: white;
  padding: 0.2rem 1rem;
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
  display: inline-block;
`

const Category = styled.div`
  padding: 2rem;
  height: 75vh;
  overflow-y: scroll;
  scrollbar-color: ${props => props.theme.colors.primary} RGBA(0, 146, 221, 0.2);
`

export default CovidFilters