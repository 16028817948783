
export const search = async (q, type = 'general') => {
  let json = null
  if (type === 'general') {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/search/ge?q=${q}`);
    json = await res.json();
  } else {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/search/co?q=${q}`);
    json = await res.json();
  }

  return json;
}