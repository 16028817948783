import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'

const fetchSettlements = async(query, updateSettlements, handleIsLoading) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/settlements?${query}`)
    updateSettlements(res.data)
    handleIsLoading(false);
  } catch (err) {
    console.log(err)
  }
}

const fetchCovidSettls = async(query, updateCovidSettls, handleIsLoading) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/settlements/covid-settls?${query}`)
    updateCovidSettls(res.data.covidMarkers);
    handleIsLoading(false);
  } catch(err) {
    console.log(err);
  }
}

const defaultContext = {
  settlements: [],
  updateSettlements: () => {},
  year: moment().format('YYYY'),
  updateYear: () => {},
  polls: [],
  updatePolls: () => {},
  settlement: null,
  updateSettlement: () => {},
  covidSettls: [],
  updateCovidSettls: () => {},
  isShowPopup: false,
  handleShowPopup: () => {},
  isMarkerSelected: false,
  handleShowMarkerSelected: () => {},
  isLoading: false,
  handleIsLoading:  () => {}
}

export const Context = React.createContext(defaultContext);

const ContextProvider = ({ children }) => {
  const [settlements, updateSettlements] = useState([])
  const [settlement, updateSettlement] = useState(null)
  const [covidSettls, updateCovidSettls] = useState([])
  const [covidSettlement, updateCovidSettlement] = useState(null)
  const [year, updateYear] = useState(moment().format('YYYY'))
  const [polls, updatePolls] = useState([])
  const [currentPoll, updateCurrentPoll] = useState(null)
  const [selected, updateSelected] = useState('covid');
  const [isShowPopup, handleShowPopup] = useState(false);
  const [isMarkerSelected, handleShowMarkerSelected] = useState(false);
  const [isLoading, handleIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async() => {
      try {        
        const polls = await axios.get(`${process.env.REACT_APP_API_URL}/polls`)
        updatePolls(polls.data)
        if (polls.data.length > 0) {
          updateYear(polls.data[0].date)       
        }
      } catch (err) {
        console.log(err)
      }
    }

    fetchData()
    fetchCovidSettls(``, updateCovidSettls)
  }, [])

  useEffect(() => {
    if (!year || !polls || polls.length === 0) {
      return false
    }

    const poll = polls.find(p => p.date === year)
    if (!poll) {
      updateYear(polls[polls.length - 1].date)
      return false
    }
    
    updateCurrentPoll(poll.id)
    if(selected === 'general') fetchSettlements(`settlements.poll_id[]=${poll.id}`, updateSettlements, handleIsLoading)
    if(selected === 'covid') fetchCovidSettls(``, updateCovidSettls, handleIsLoading)

  }, [polls, year, selected])


  return (
    <Context.Provider
    value={{
        polls,
        settlements,
        updateSettlements,
        year,
        updateYear,
        settlement,
        updateSettlement,
        covidSettls,
        updateCovidSettls,
        selected,
        updateSelected,
        fetchSettlements,
        currentPoll,
        fetchCovidSettls,
        covidSettlement,
        updateCovidSettlement,
        isShowPopup,
        handleShowPopup,
        isMarkerSelected,
        handleShowMarkerSelected,
        isLoading,
        handleIsLoading
      }}
    >
      { children }
    </Context.Provider>
  )
}

export default ContextProvider