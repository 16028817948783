import React from 'react';
import styled from 'styled-components';

import CloseButton from '../CloseButton'

const SearchAndFiltersBar = ({ children, handleBarShowed }) => {
  return (
    <Wrapper>
      <CloseButton 
        handleBarShowed={handleBarShowed} 
      />
      <div>{children}</div>
    </Wrapper>
  )
}

export default SearchAndFiltersBar;

const Wrapper = styled.div`
  z-index: 10;
  display: block;
  background: ${props => props.theme.colors.primary};


  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  
  width: 30vw;

  position: absolute;
  top: 0;
  right: 0;  

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
  }
`