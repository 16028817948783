import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      fill="none"
      viewBox="0 0 38 38"
    >
      <path
        stroke="#333"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M13.063 23.75a7.719 7.719 0 100-15.437 7.719 7.719 0 000 15.437z"
      ></path>
      <path
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M23.069 8.6a7.72 7.72 0 112.094 15.15M2.375 29.301a13.065 13.065 0 0121.375 0M25.164 23.75A13.046 13.046 0 0135.85 29.3"
      ></path>
    </svg>
  );
}

export default Icon;
