const theme = {
  fontSizes: {
    xxs: '9px',
    xs: '12px',
    sm: '14px',
    base: '16px',
    medium: '24px',
    lg: '32px',
    xlg: '40px',
    xl: '48px',
    xxl: '64px'
  },
  texts: {
    dark: '#333'
  },
  colors: {
    primary: '#0092dd',
    secondaryBlue: '#0785C6',
    covid: '#ea6d4f',
    dark: '#333',
    gray: '#BAC0CB',
    error: '#ed4337',
    green: '#00DD88',
    lightBlue: "#2680FF",
    white: '#fff'
  },
  breakpoints: {
    xs: '480px',
    sm: '768px',
    md: '1024px',
    lg: '1311px',
  },
  flags: {
    major: '#4E71F3',
    minor: '#6CE278',
    hotfix: '#FB9A79'
  },
  buttons: {
    primary: {
      color: 'white',
      bg: 'primary',
    },
    outline: {
      color: 'primary',
      bg: 'transparent',
      boxShadow: 'inset 0 0 0 2px'
    },
  },
}

export default theme