import React from 'react'
import styled from 'styled-components'
import Chevron from '../../../Icons/Chevron'
import { clone } from 'lodash'

const CategoryTitle = ({ title, onClick, selected, value, categories }) => {
  return (
    <Wrapper onClick={() => {
      let newCategories = clone(categories)
      newCategories.push(value)
      onClick(selected ? categories.filter(c => c !== value) : newCategories)
    }} >
      <p>
        { title }
      </p>
      <ChevronWrapper selected={selected}>
        <Chevron />
      </ChevronWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border-bottom: 1px solid #fff;
  padding: 0.5rem 0;  
  display: flex;
  justify-content: space-between;

  > p {
    margin: 0;
    color: white
  }
`

const ChevronWrapper = styled.div`  
  transition: all 0.2s;

  ${ props => props.selected && `  
    transform: rotate(180deg);
  `}
`


export default CategoryTitle