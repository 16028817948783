import React from "react";

function Icon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M16 17a4 4 0 100-8 4 4 0 000 8z"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M26 13c0 9-10 16-10 16S6 22 6 13a10 10 0 1120 0v0z"
      ></path>
    </svg>
  );
}

export default Icon;
