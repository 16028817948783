import React from 'react';
import styled from 'styled-components';

const SettlementTitle = ({ settlName, dimension }) => {
  return (
    <Wrapper>
      <Dimension>
        {dimension}
      </Dimension>
      <DimensionName>
        { settlName }
      </DimensionName>
      </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 2rem 0 1rem 2rem;
`

const Dimension = styled.div`
  text-transform: uppercase;
  color: ${props => props.theme.colors.primary };
  font-size: ${props => props.theme.fontSizes.sm};
  line-height: 17px;
  font-style: normal;
  font-weight: normal;
  padding: 0.1rem 0;
`

const DimensionName = styled.div`
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes.xlg};
  line-height: 50px;
  font-weight: 400;
`

export default SettlementTitle;