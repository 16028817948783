import React, { useState, useEffect, useContext, useRef } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import MapGL, { AttributionControl, Marker, Popup, LinearInterpolator, WebMercatorViewport } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from 'mapbox-gl';
import { Context } from '../../utils/context'
import Plus from '../Icons/Plus'
import Minus from '../Icons/Minus'

import EllipseIcon from '../Icons/Ellipse'

import SocialMedia from '../SocialMedia'

// See documentation: https://styled-components.com/docs/advanced#existing-css.
// We'd to do that because we're using 3rd party library with existing css classes that we needed to modify.
import '../Map/styles.css'

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN

const defaultViewport = {
  latitude: -20.292025,
  longitude: -61.244984,
  minZoom: 2.5,
  zoom: 2.5,
  bearing: 0,
  pitch: 0
}

const Map = ({history, onRefUpdate}) => {
  const context = useContext(Context)
  const [popupInfo, handlePopupInfo] = useState(null);
  const [lastPosition, handleLastPosition] = useState({})

  let filteredCovidSettls = context.covidSettls.filter(c => c.covid_latitude !== "" && c.covid_longitude !== "");

  const [viewport, setViewport] = useState(defaultViewport);


  // Scale Marker's size if user zooms in.
  // Check MarkerStyle in styled-component.
  const scale = 1 + (viewport.zoom - 2.5) * 0.7;

  // Child to parent communication. Popup ref was needed from this component. 
  // This is updating the popupRef value in parent component (<App>).
  const ref = useRef();
  const handleRef = (val) => {
    onRefUpdate(val);
  }

  useEffect(() => {    
    if (context.settlement) {
      setViewport({
        ...viewport,
        longitude: parseFloat(context.settlement.longitude ? context.settlement.longitude : popupInfo.covid_longitude),
        latitude: parseFloat(context.settlement.latitud ? context.settlement.latitud : popupInfo.covid_latitude),
        transitionInterpolator: new LinearInterpolator(),
        transitionDuration: 2000,
        zoom: 12
      })
    } else {
      setViewport({
        ...defaultViewport,
        ...lastPosition,
        transitionInterpolator: new LinearInterpolator(),
        transitionDuration: 2000,        
      });
    }
  }, [context.settlement])

  useEffect(() => {      
    if (context.covidSettlement) {
      setViewport({
        ...viewport,
        longitude: parseFloat(context.covidSettlement.longitude),
        latitude: parseFloat(context.covidSettlement.latitude),
        transitionInterpolator: new LinearInterpolator(),
        transitionDuration: 2000,
        zoom: 12
      })
    } else {
      setViewport({
        ...defaultViewport,
        ...lastPosition,
        transitionInterpolator: new LinearInterpolator(),
        transitionDuration: 2000,
      });
    }
  }, [context.covidSettlement])

  return (
    <>
    <MapWrapper 
      small={!!(context.settlement || context.covidSettlement)}
      height={context.isMarkerSelected}
      avoidPointingMap={context.isMarkerSelected}
    >
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle={'mapbox://styles/juanlacueva/ckqa4q0zc2l0s17qmz26qomhw?optimize=true'}
        onViewportChange={setViewport}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        attributionControl={false}
        dragPan={!context.settlement}
        touchZoom={!context.settlement}
        doubleClickZoom={!context.settlement}
        scrollZoom={!context.settlement}
      >          
        {context.isShowPopup && popupInfo && !context.settlement && (
          <Popup
            className='mapboxgl-popup'
            tipSize={5}
            anchor="top"
            altitude={50}
            latitude={parseFloat(popupInfo.latitude)}
            longitude={parseFloat(popupInfo.longitude)}
            closeOnClick={false}
            onClose={() => {
              handlePopupInfo(null)
            }}
          >
            <PopupWrapper ref={ref}                 
              onClick={() => {         
                  handleLastPosition({
                    zoom: viewport.zoom,
                    latitude: viewport.latitude,
                    longitude: viewport.longitude
                  }) 
                  setViewport({
                    ...viewport,
                    longitude: parseFloat(popupInfo.longitude),
                    latitude: parseFloat(popupInfo.latitude),
                    transitionInterpolator: new LinearInterpolator(),
                    transitionDuration: 2000,
                    zoom: viewport.zoom
                  })
                  context.handleShowMarkerSelected(true);
                  context.updateSettlement(popupInfo);
                  if (context.selected === 'covid') {
                    context.updateCovidSettlement(popupInfo)
                    history.push(`/covid/${popupInfo.settlement_id}`)
                  } else { 
                    context.updateSettlement(popupInfo)
                    history.push(`/asentamientos/${popupInfo.base_id}`)
                  }
                }}
>
              <div>
                <p>
                  { popupInfo.name }
                </p>
                <span>
                  { `${popupInfo.province ? popupInfo.province : '' }${popupInfo.province && popupInfo.country ? ',' : ''} ${popupInfo.country ? popupInfo.country : ''}` }
                </span>
              </div>
              <div>
                <Plus />
              </div>
            </PopupWrapper>
          </Popup>
        )}
        
        { context.selected === 'general' && 
          context.settlements.map((settlement, index) => (
          <Marker 
            onClick={() => {
              handlePopupInfo({
                ...settlement,
                latitude: settlement.latitud
              })
              context.handleShowPopup(true);
              handleRef(ref);

            }}
            key={`marker-${index}`}
            longitude={parseFloat(settlement.longitude)}
            latitude={parseFloat(settlement.latitud)}
          >
            { context.isMarkerSelected && popupInfo && popupInfo.settlement_id === settlement.settlement_id 
              ? <SelectedMarkerWrapper>
                  <EllipseIcon />
                  <MarkerStyle
                    backgroundColor={props => props.theme.colors.white}
                    selectedMarker={true}
                  />
                </SelectedMarkerWrapper>
              : <MarkerStyle 
                  hideMarkersNotSelected={context.isMarkerSelected}
                  backgroundColor={props => props.theme.colors.white}
                  scale={scale}
                />
            }   
          </Marker>
        ))}

        { context.selected === 'covid' && 
          filteredCovidSettls.map((covidSettl, index) => (
          <Marker 
            onClick={() => {
              handlePopupInfo({
                ...covidSettl,
                latitude: parseFloat(covidSettl.settlement_latitude ? covidSettl.settlement_latitude : covidSettl.covid_latitude),
                longitude: parseFloat(covidSettl.settlement_longitude ? covidSettl.settlement_longitude : covidSettl.covid_longitude)
              })
              context.handleShowPopup(true);
              handleRef(ref)
            
            }}
            key={`marker-${index}`}
            latitude={parseFloat(covidSettl.settlement_latitude ? covidSettl.settlement_latitude : covidSettl.covid_latitude)}
            longitude={parseFloat(covidSettl.settlement_longitude ? covidSettl.settlement_longitude : covidSettl.covid_longitude)}
          >
            { context.isMarkerSelected && popupInfo && popupInfo.settlement_id === covidSettl.settlement_id 
              ? <SelectedMarkerWrapper>
                  <EllipseIcon />
                  <MarkerStyle
                    backgroundColor={props => props.theme.colors.covid}
                    selectedMarker={true}
                  />
                </SelectedMarkerWrapper>
              : <MarkerStyle 
                  backgroundColor={props => props.theme.colors.covid}
                  hideMarkersNotSelected={context.isMarkerSelected}
                  scale={scale}
                />
            }  
          </Marker>
        ))}

        <AttributionControl compact={true} style={{
          display: 'none'
        }} />
      </MapGL>

      { !context.settlement && (
        <MapControls>
          <ZoomButton onClick={(e) => {
            const newViewport = {
              ...viewport,
              zoom: parseInt(viewport.zoom + 1)
            }

            setViewport(newViewport)
          }}>
            <Plus color="#fff"/>
          </ZoomButton>

          <ZoomButton onClick={(e) => {          
            const newViewport = {
              ...viewport,
              zoom: parseInt(viewport.zoom - 1)
            }

            setViewport(newViewport)
          }}>
            <Minus />
          </ZoomButton>
        </MapControls>
      )}
    
    </MapWrapper>

    { context.isMarkerSelected && 
      <SocialMedia covidInfo={popupInfo} />
    }
  </>
  )
}

const MapWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  transition: all 0.2s;

  ${props => props.height && `
    height: 85vh;
  `}

  ${props => props.small && `
    width: 40vw;  
  `}

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    ${props => props.small && `
      width: 100vw;
    `}

    ${props => props.avoidPointingMap && `
      pointer-events: none;
    `}
  }
`

const PopupWrapper = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  
  > div { 
    > p {
      margin: 0;
      color: ${props => props.theme.colors.primary};    
    }

    > span {
      font-size: 14px;
    }

    > svg {
      margin-left: 3rem;
      width: 35px;
      height: 35px;
    }
  }
`

const SelectedMarkerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  right: 10px;
  bottom: 8px;
`

const MarkerStyle = styled.div`
  position: absolute;
  height: 8px;
  width: 8px;
  background: ${props => props.backgroundColor};
  border-radius: 50%;

  ${({ hideMarkersNotSelected }) => hideMarkersNotSelected && `
    display: none;
  `};

  box-shadow: 0px 0px 3px 1px rgba(255,255,255,0.75); 

  ${({ selectedMarker }) => selectedMarker && `
    width: 12px;
    height: 12px;
    box-shadow: none;
  `};

  transform: scale(${props => props.scale});
`

const MapControls = styled.div`
  position: absolute;
  bottom: 60px;
  right: 0;
  padding: 2rem 2.5rem;

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    bottom: 100px;
  }
`

const ZoomButton = styled.div`
  background: transparent;
  border-radius: 50%;
  border: 1px solid #fff;
  background: ${props => props.theme.colors.primary};
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  cursor: pointer;

  > svg {
    height: 20px;
    width: 20px;
  }

`

export default withRouter(Map)