import React, { useContext, useRef, useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import styled, { ThemeProvider } from 'styled-components'
import Map from './components/Map'
import { Context } from './utils/context'

// CSS Theming
import theme from './utils/theme';
import GlobalStyle from './utils/globalStyle'

// Scenes
import Home from './scenes/Home'
import SettlementDetails from './scenes/SettlementDetails'
import CovidDetails from './scenes/CovidDetails'
import SplashScreen from './components/SplashScreen'

function App() {

  const context = useContext(Context);

  // Child to parent communication. Popup ref was needed from <Map> component. 
  const [popupRef, setPopupRef] = useState();
  const updatePopupRef = (refVal) => {
    setPopupRef(refVal);
  }

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (context.isShowPopup && popupRef.current && !popupRef.current.contains(e.target)) {
        context.handleShowPopup(false)
      }
    }
    
    // Check device being used to access the site.
    if(/Android|webOS|iPhone|iPad|iPod|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
      document.addEventListener('touchstart', checkIfClickedOutside)
      // document.write("mobile device");
    }else{
      // document.write("not mobile device");
      document.addEventListener("mousedown", checkIfClickedOutside)
    }


    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
      document.addEventListener('touchstart', checkIfClickedOutside)
    }
  }, [popupRef])

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Dimension>
          { context.isLoading && (
            // TODO leave animation
            <SplashScreen />
          )}
          <GlobalStyle />
          
          <div>
            <Router>
             
              <Switch>
                <Route exact path="/asentamientos/:settlementId" component={SettlementDetails} />
                <Route exact path="/covid/:settlementId" component={CovidDetails} />
                <Route exact path="/" component={Home}/>
              </Switch>
              <div>
                <Map onRefUpdate={updatePopupRef} />
              </div>
            </Router>
          </div>
        </Dimension>
      </ThemeProvider>
    </div>
  );
}


const Dimension = styled.div`
  position: relative;
  height: 100vh;
`

export default App;
