import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.757 21.757L19 26l4.243-4.243M19 16v10M12 26H9a7.001 7.001 0 01-3.931-12.787 7 7 0 015.67-.995M10 16a10 10 0 1118 6"
      ></path>
    </svg>
  );
}

export default Icon;
