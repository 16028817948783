import axios from 'axios';

export const fetchCovidSettlement = async(settlementId, handleCovidData, updateCovidSettlement = null) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/covid/show?settlement_id=${settlementId}`)
      handleCovidData(res.data)

      if (updateCovidSettlement) {
        updateCovidSettlement(res.data.data[0])
      }
    } catch (err) {
      console.log('error')
    }
}


export const fetchSettlementDetails = async(settlementId, updateSettlement) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/settlements/details?id=${settlementId}`)
    updateSettlement(res.data)
  } catch (err) {
    console.log('error')
  }
}