import React from 'react';

function Icon() {
    return (
        <svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="19.5" stroke="#F4B740"/>
        </svg>
    )
}

export default Icon;



