import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import BlueClose from '../Icons/BlueClose'

import Dimensions from './components/Dimensions'
import SettlementTitle from './components/SettlementTitle'
import SettlementDescription from './components/SettlementDescription'
import CovidTitle from './components/CovidTitle'
import CovidDescription from './components/CovidDescription'
import axios from 'axios'

const test = [
  {
    "Dimension": '',
    "Nombre": 'Agua',
    "Personas": '1222',
    "Objetivo": "Construir una casa barrial para que los vecinos tengan su propio espacio adecuado para las reuniones",
    "Costo": "1200"
  },
  {
    "Dimension": '',
    "Nombre": 'Agua',
    "Personas": '1222',
    "Objetivo": "Construir una casa barrial para que los vecinos tengan su propio espacio adecuado para las reuniones",
    "Costo": "1200"
  },
]

const fetchProjects = async(settlementId, handleProjects) => {
  try {
    const res = await  axios.get(`https://comunidades.techo.org/comunidades/api/proyectos/${settlementId}/`)
    handleProjects(res.data.proyectos)
  } catch (err) { 
    console.log(err)
  }
}

const Details = ({ 
    dimension,
    dimensions, 
    handleDimension, 
    keys,
    handleKeys, 
    covidData, 
    settlementData,
    onClose,
}) => {
  const [projects, handleProjects] = useState([])

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  useEffect(() => {
    if (settlementData) {
      fetchProjects(settlementData.base_settlement_id, handleProjects)
    }
  }, [])

  useEffect(() => {
    if (settlementData) {
      fetchProjects(settlementData.base_settlement_id, handleProjects)
    }
  }, [settlementData])

  return (
    <Wrapper>
      <Exit>        
        <BlueCloseWrapper onClick={onClose}>
          <BlueClose />      
        </BlueCloseWrapper>
      </Exit>

      <Heads>
        <Dimensions 
          dimensions={dimensions}
          handleDimension={handleDimension} 
          handleKeys={handleKeys}
          hasCovid={!!(covidData && covidData.length > 0)}
        />
      </Heads>

      { settlementData && dimension !== 'Covid-19' && (
       <React.Fragment>
          <SettlementTitle 
            settlName={settlementData.name}
            dimension={dimension} 
          />

          <SettlementDescription 
            settlement={settlementData}
            keys={keys}
          />       

          
          { dimension === 'Información general' && (
            <>
              <BlueSeparator />
              <Projectos>
                <p>
                  PROYECTOS
                </p>
                { projects.map((p, i) => (
                  <ProyectWrapper key={i}>
                    <PointWrapper>
                      <Point/>
                      <div>
                        {p['Nombre'] || ' - '}
                      </div>

                    </PointWrapper>
                    <Projecto>
                      <div>
                        <span>Dimension:</span> {p['Dimension'] || ' - '}
                      </div>
                      <div>
                        <span>Personas:</span> {p['Personas'] || ' - '}
                      </div>
                      <div>
                        <span>Objetivo:</span> {p['Objetivo'] || ' - '}
                      </div>
                      <div>
                        <span>Costo:</span> USD {p['Costo'] ? formatter.format(p['Costo']) : ' - '}
                      </div>
                    </Projecto>
                  </ProyectWrapper>
                ))}
              
              </Projectos>
            </>
          )}
        </React.Fragment>
      )}      

      {covidData && covidData.length > 0 && dimension == 'Covid-19' &&
        <React.Fragment>
          <CovidTitle 
            covidSettlName={covidData[0].name}
            dimension='Covid-19' 
          />
            <CovidDescription 
              keys={keys}
              covidData={covidData}
            /> 
        </React.Fragment>
      }
            
    </Wrapper>
  )
}

const BlueCloseWrapper = styled.div``

const Wrapper = styled.div`
  z-index: 10;
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  width: 60vw;
  background: white;
  overflow-y: scroll;

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    position: relative;
    width: 100vw;
    height: auto;

    padding: 0;
  }
`

const Heads = styled.div`
  display: flex;
  flex-grow: 1;

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    background: #E6F3F3;
  }
`

const Exit = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    background: #E6F3F3;
    justify-content: center;
  }
`

const BlueSeparator = styled.div`
  margin: 1rem 2rem;
  height: 1.5px;
  border-radius: 2px;
  width: 80%;
  background: ${props => props.theme.colors.primary};
`

const Projecto = styled.div`
  max-width: 360px;
  padding: 0.5rem 0 0.5rem 1rem;

  > div {
    padding: 0.3rem 0;
    font-size: 14px;
    > span {
      color: ${props => props.theme.colors.primary};
    }
  }
`

const Projectos = styled.div`
  padding: 0 2rem;

  > p {
    color: ${props => props.theme.colors.primary};
  }
`

const PointWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.primary};
  font-size: 16px;
  font-weight: 600;
`

const Point = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin-right: 1rem;
  background: ${props => props.theme.colors.primary};
`

const ProyectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
`
export default Details