import React from "react";
import Logo from "../img/EUAV.png"

const EUAV = () => {
return (
  <img src={Logo} alt={'Logo EUAV'} />
)

}

export default EUAV