
import React from "react";

function Icon() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 31.5C25.4558 31.5 31.5 25.4558 31.5 18C31.5 10.5442 25.4558 4.5 18 4.5C10.5442 4.5 4.5 10.5442 4.5 18C4.5 25.4558 10.5442 31.5 18 31.5Z" stroke="#0092DD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M23.625 12.3751H21.375C20.4799 12.3751 19.6214 12.7307 18.9885 13.3636C18.3556 13.9966 18 14.855 18 15.7501V31.5001" stroke="#0092DD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.5 20.2501H22.5" stroke="#0092DD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default Icon;