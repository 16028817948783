import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M16 10v7"
      ></path>
      <path
        stroke="#333"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M20.556 4h-9.112a1 1 0 00-.708.293l-6.443 6.443a1 1 0 00-.293.708v9.112a1 1 0 00.293.707l6.443 6.444a1 1 0 00.708.293h9.112a1 1 0 00.707-.293l6.444-6.444a1 1 0 00.293-.707v-9.112a1 1 0 00-.293-.708l-6.444-6.443A1 1 0 0020.556 4z"
      ></path>
      <path
        fill="#333"
        stroke="#333"
        strokeWidth="1.5"
        d="M16.75 21.5a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
      ></path>
    </svg>
  );
}

export default Icon;
