import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Context } from '../../utils/context'

import { downloadGeneral, downloadCovid } from './download'

import DownloadIcon from '../../components/Icons/Download'

const DownloadBar = ({ selected }) => {
  const context = useContext(Context);

  const [downloadStatus, setDownloadStatus] = useState(null);

  const updateDownloadStatus = (val) => {
    setDownloadStatus(val)
  }

  // Find year selected and grab the pollId that is needed as parameter in downloadGeneral method.
  // let poll = polls.filter(p => p.date === year);
  // let pollId = poll[0].id;

  return (
    <Wrapper>
      <Text>
      Los censos son investigaciones socio-territoriales en asentamientos populares que, usando metodologías participativas, pretenden dar respuestas a la ausencia de diagnósticos territoriales nacionales y regionales y a la falta de información que invisibiliza la comprensión de esta realidad.<br /><br />
El levantamiento es realizado por encuestadores/as que recorren todo el territorio. Una vez allí, detectan los asentamientos y entrevistan a los/as referentes/as comunitarios, quienes proporcionan la información correspondiente a cada territorio.
      </Text>
      { selected === 'general' ? 
        <IconWrapper onClick={() => downloadGeneral(context.years, context.polls, updateDownloadStatus)} >
          <DownloadIcon />
        </IconWrapper>


      : <IconWrapper onClick={() => downloadCovid()} >
          <DownloadIcon />
        </IconWrapper>
        
      }

      { downloadStatus === 'error' && 
        <Text>
          No existen datos abiertos para la opción seleccionada.
        </Text>
      }
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Text = styled.p`
  margin: 0 3rem;
  font-size: ${props => props.theme.fontSizes.base};
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: ${props => props.theme.colors.white};

  @media(max-width: ${props => props.theme.breakpoints.md}) {
    margin: 0 1.5rem;
  }

  color: ${props => props.theme.colors.white};

  text-align: center;
`

const IconWrapper = styled.div`
  margin: 1.5rem 0;
  display: flex;
  justify-content: center;

  cursor: pointer;
`

const DownloadError = styled.div`

`

export default DownloadBar