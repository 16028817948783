import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9.5 14a4.5 4.5 0 100-9 4.5 4.5 0 000 9zM22.5 14a4.5 4.5 0 100-9 4.5 4.5 0 000 9zM9.5 27a4.5 4.5 0 100-9 4.5 4.5 0 000 9zM22.5 19v7M26 22.5h-7"
      ></path>
    </svg>
  );
}

export default Icon;
